<template>
  <summary-wrapper :groupedSummaries="orderByModel(groupedSummaries)"
                   :groupedResponses="groupedResponses"
                   :routeback="route.modelsCompare"
                   :loading="loadingData">

    <summary-options>
      <date-selector :show-label="false" />
    </summary-options>

    <template #no-data-message>There is no data {{ this.noData ? "for the selected date" : "available" }}.</template>

  </summary-wrapper>
</template>

<script>
import SummaryWrapper from "@/components/page-elements/SummaryWrapper.vue";
import SummaryOptions from "@/components/page-elements/SummaryOptions.vue";
import DateSelector from "@/components/form-elements/DateSelector.vue";
import { ROUTE, AiModelDisplayNames } from "@/constants";


export default {
  props: ["groupedSummaries", "groupedResponses", "noData", "loadingData"],
  data() {
    return {
      route: ROUTE,
    };
  },
  components: {
    SummaryWrapper,
    SummaryOptions,
    DateSelector,
  },
  methods: {
    orderByModel(groupedSummaries) {
      const modelArr = Object.keys(AiModelDisplayNames)
      const ordered = groupedSummaries.sort((a, b) => {
        return modelArr.indexOf(a.summaries[0].model_type) - modelArr.indexOf(b.summaries[0].model_type);
      });
      return ordered;
    },
  },
};
</script>
