<template>
  <view-wrapper>
    <div class="top-section">
      <div class="d-flex flex-column header-section">
        <div class="prompt-group-dropdown">
          <div class="dropdown d-flex align-items-center">
            <span class="button-text">Track</span>
            <client-dropdown :shouldShowPeers="true" />
          </div>
        </div>
        <span class="mt-1 reputation-title">Track {{ aiPromptGroup?.display_name }} across time</span>
      </div>

      <tabs :selected-tab="selectedTab" @tab-selected="selectTab" />

    </div>

    <!-- Content based on the selected tab -->
    <div :class="{ hide: selectedTab !== 'summaries' }"
         class="tab-content">
      <Summary :grouped-summaries="groupedSummaries"
               :grouped-responses="groupedResponses"
               :no-data="noSummaryData"
               :loading-data="loadingData" />
    </div>
    <div :class="{ hide: selectedTab !== 'responses' }" class="tab-content">
      <Response :grouped-responses="groupedResponses" :no-data="noResponseData" :loading-data="loadingData" />
    </div>
  </view-wrapper>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ViewWrapper from "@/views/ViewWrapper.vue";
import Summary from "@/components/entity-tracker/Summary.vue";
import Response from "@/components/entity-tracker/Response.vue";
import Tabs from "@/components/form-elements/Tabs.vue";
import ClientDropdown from "@/components/form-elements/ClientDropdown.vue";
import { _ } from "vue-underscore";

export default {
  components: {
    ViewWrapper,
    Summary,
    Response,
    Tabs,
    ClientDropdown,
  },

  data() {
    return {
      dateOptions: [],
      summaries: [],
      responses: [],
      noSummaryData: false,
      noResponseData: false,
      loadingData: false,
    };
  },
  methods: {
    ...mapActions("general", ["setView"]),
    ...mapActions("ai", [
      "setAiTag",
      "setAiEngineModel",
      "setAiPromptGroup",
      "setAiEngineModels",
      "setSelectedTimeRange",
      "initializeAI",
      "setAppropriatePromptGroup",
      "setSelectedPrompt",
      "setSelectedTab",
      "toggleResponseOpen",
      "setOpenResponses",
    ]),

    selectTab(tab) {
      this.setSelectedTab(tab);
      this.scrollTimeline();
    },

    scrollTimeline() {
      window.setTimeout(() => {
        const lastCards = document.querySelectorAll('.scroll-into-view');
        const scroller = document.querySelector('.timeline-scrolling-container');
        const v = scroller.scrollTop;
        Array.from(lastCards).forEach(card => card.scrollIntoView({ behavior: "instant", inline: "end" }));
        scroller.scrollTop = v;
      }, 0);
    },

    async fetchData(promptGroupId, selectedTimeRange) {
      try {
        this.loadingData = true;
        const [summariesResult, responsesResult] = await Promise.all([
          this.$api.mongoGetSummariesByPromptGroup(promptGroupId, selectedTimeRange),
          this.$api.mongoGetResponsesByPromptGroup(promptGroupId, selectedTimeRange),
        ]);

        this.summaries = summariesResult?.data || [];
        this.responses = responsesResult?.data || [];

        this.noSummaryData = !this.summaries.length;
        this.noResponseData = !this.responses.length;
      } catch (error) {
        console.error("Error fetching data:", error);
        this.noSummaryData = true;
        this.noResponseData = true;
      }
      finally {
        this.loadingData = false;
      }

      this.scrollTimeline();
    },

    async fetchDataIfNeeded() {
      if (this.aiPromptGroup?.id && this.selectedTimeRange) {
        this.fetchData(this.aiPromptGroup.id, this.selectedTimeRange);
      }
    },

    toggleOpenAll() {
      this.setOpenResponses(this.isAllOpen ? [] : [...Array(this.groupedResponses?.[0]?.responses?.length).keys()]);
    },
  },
  watch: {
    aiPromptGroup() {
      this.fetchDataIfNeeded();
      this.setOpenResponses([0]);
    },
    selectedTimeRange(currentValue, previousValue) {
      if (previousValue && this.aiPromptGroup?.id) {
        this.fetchData(this.aiPromptGroup.id, currentValue);
      }
    },
    aiEngineModel() {
      this.scrollTimeline();
    },
  },
  computed: {
    ...mapGetters("ai", {
      aiPromptGroup: "aiPromptGroup",
      aiPromptGroups: "aiPromptGroups",
      aiEngineModels: "aiEngineModels",
      selectedTimeRange: "selectedTimeRange",
      aiEngineModel: "aiEngineModel",
      selectedTab: "selectedTab",
      openResponses: "openResponses",
    }),
    uniqueAiPrompts() {
      const responses = this.groupedResponses;
      const aiPrompts = _.chain(responses)
        .map((group) => group.responses) // Extract 'responses' array from each group
        .flatten() // Flatten the array of arrays
        .pluck("ai_prompt") // Extract 'ai_prompt' property from each object
        .compact() // Remove null or undefined values
        .uniq() // Get unique values
        .value(); // Extract the final result from the chain

      return aiPrompts;
    },

    filteredSummaries() {
      return this.summaries.filter((summary) => {
        const isModelTypeMatch = summary.model_type === this.aiEngineModel.model_type;
        const isPromptGroupIdMatch = summary.ai_prompt_group_id === this.aiPromptGroup.id;

        return isModelTypeMatch && isPromptGroupIdMatch;
      });
    },

    groupedSummaries() {
      const summaries = this.filteredSummaries;
      const grouped = [];

      // Iterate through sorted summaries and group them by date
      summaries.forEach((summary) => {
        const date = this.$dateTime.fromISO(summary.datetime).toFormat("MM/dd/yyyy");
        const key = date + "-" + summary.model_type;

        // Check if there is already a group with the same key
        const existingGroup = grouped.find((group) => group.key === key);

        if (existingGroup) {
          // Add the summary to the existing group
          existingGroup.summaries.push(summary);
        } else {
          // Create a new group and add the summary

          // TODO: I had to add another field sortKey to fix the sorting, but
          // couldn't easily remove the old key because it doubles as info sent to the header.
          // Should be decoupled to make maintenance easier. (DM-3210)
          const newGroup = {
            key: key,
            sortKey: summary.datetime,
            summaries: [summary],
          };
          grouped.push(newGroup);
        }
      });

      // Sort the grouped array based on the keys in ascending order
      grouped.sort((a, b) => a.sortKey.localeCompare(b.sortKey));


      return grouped;
    },

    filteredResponses() {
      return this.responses.filter((response) => {
        const isModelTypeMatch = response.model_type === this.aiEngineModel.model_type;
        const isPromptGroupIdMatch = response.ai_prompt_group_id === this.aiPromptGroup.id;

        return isModelTypeMatch && isPromptGroupIdMatch;
      });
    },

    sortedFilteredResponses() {
      // Create a copy of filteredSummaries to avoid mutating the original array
      const responseCopy = [...this.filteredResponses];

      // Sort the array by datetime in descending order (latest first)
      responseCopy.sort((a, b) => {
        const dateA = new Date(a.datetime);
        const dateB = new Date(b.datetime);

        return dateB - dateA;
      });

      return responseCopy;
    },
    groupedResponses() {
      const responses = this.sortedFilteredResponses;
      const grouped = [];

      // Iterate through sorted responses and group them by date
      responses.forEach((response) => {
        const date = this.$dateTime.fromISO(response.datetime).toFormat("MM/dd/yyyy");
        const key = date + "-" + response.model_type;

        // Find the index of the group in the array
        const index = grouped.findIndex((group) => group.key === key);

        if (index === -1) {
          // If the group doesn't exist, create a new one

          // TODO: I had to add another field sortKey to fix the sorting, but
          // couldn't easily remove the old key because it doubles as info sent to the header.
          // Should be decoupled to make maintenance easier. (DM-3210)
          grouped.push({ key, sortKey: response.datetime, responses: [response] });
        } else {
          // If the group already exists, add the response to the existing group
          grouped[index].responses.push(response);
        }
      });

      // Sort the grouped array based on the keys in ascending order
      grouped.sort((a, b) => a.sortKey.localeCompare(b.sortKey));

      return grouped;
    },

    isAllOpen() {
      return this.groupedResponses?.[0]?.responses?.length === this.openResponses.length;
    },
  },

  mounted() {

    this.setView("entityTracker");
    this.initializeAI().then(this.setAppropriatePromptGroup);
    this.fetchDataIfNeeded();
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";

.headers-wrapper {
  display: inline-flex;
  gap: var(--card-spacing);
}

.top-section {
  position: sticky;
  left: var(--gutter-width);
  max-width: max-content;
  z-index: calc(var(--z-sticky-baseline) + 3);

  .header-section {
    margin-bottom: var(--component-spacing-v);
  }

  .reputation-title {
    @include title-styles;
    font-size: 14px;
  }

  .prompt-group-dropdown {
    .dropdown {
      @include title-styles;
      font-size: 32px;
      gap: 1rem;
    }
  }

  .button-text {
    color: #545f71;
  }
}

.hide {
  // hide in this way so it still has a height when we measure for aligning the boxes vertically
  position: absolute;
  top: -10000px;
}
</style>
