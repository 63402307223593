<template>
  <div class="chart-peer-compare" v-if="summariesWithTag.length">
    <span class="title">How frequently does #{{ aiTag.tag.toLowerCase() }} appear in {{ modelType }} as compared to my peers</span>
    <div class="chart-container" v-if="chartDataForTag?.labels">
      <Line :data="chartDataForTag" :options="chartOptionsForTag" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { _ } from "vue-underscore";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "vue-chartjs";
import { AiModelDisplayNames } from "@/constants";
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default {
  data() {
    return {
      chartDataForTag: [],
    };
  },
  components: {
    Line,
  },
  props: {
    summariesWithTag: Array,
  },
  computed: {
    ...mapGetters("ai", {
      aiTag: "aiTag",
      aiPromptGroup: "aiPromptGroup",
      aiEngineModel: "aiEngineModel",
    }),
    modelType() {
      return AiModelDisplayNames[this.aiEngineModel.model_type];
    },
    chartOptionsForTag() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: "bottom", // Move legend to the bottom
            labels: {
              fontColor: "#3C4149",
            },
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            beginAtZero: true,
          },
        },
      };
    },
  },
  methods: {
    orderByDate(groupedData) {
      const orderedData = groupedData.sort((a, b) => {
        if (a === b) return 0;
        if (a < b) return -1;
        else return 1;
      });
      return orderedData;
    },
    groupDataForPromptGroup(data, promptGroupId, modelType, tagId, uniqueDates) {
      const groupedData = uniqueDates.reduce((result, date) => {
        let recordsForDate = [];
        if (modelType == 'all') {
          recordsForDate = data.filter(
            (record) =>
              record.ai_prompt_group_id === promptGroupId &&
              record.tags.some((tag) => tag.ai_prompt_template_tag_id === tagId) &&
              record.datetime.split("T")[0] === date
          );
        } else {
          recordsForDate = data.filter(
            (record) =>
              record.ai_prompt_group_id === promptGroupId &&
              record.model_type === modelType &&
              record.tags.some((tag) => tag.ai_prompt_template_tag_id === tagId) &&
              record.datetime.split("T")[0] === date
          );
        }
        result[date] = recordsForDate.length;
        return result;
      }, {});

      return groupedData;
    },
    groupDataForPeer(data, promptGroupId, modelType, tagId) {
      let groupedData = []
      if (modelType == 'all') {
        groupedData = _.chain(data)
          .filter((record) => record.ai_prompt_group_id !== promptGroupId)
          .filter((record) => record.tags.some((tag) => tag.ai_prompt_template_tag_id === tagId))
          .groupBy((record) => record.datetime.split("T")[0])
          .mapObject((records) => records.length)
          .sortBy((value, key) => key) // Sort by date
          .value();
      } else {
        groupedData = _.chain(data)
          .filter((record) => record.ai_prompt_group_id !== promptGroupId)
          .filter((record) => record.model_type === modelType)
          .filter((record) => record.tags.some((tag) => tag.ai_prompt_template_tag_id === tagId))
          .groupBy((record) => record.datetime.split("T")[0])
          .mapObject((records) => records.length)
          .sortBy((value, key) => key) // Sort by date
          .value();
      }

      return groupedData;
    },
    prepareChartDataForTag() {
      // Get unique prompt group IDs and names from the data
      const uniquePromptGroupIds = [
        ...new Set(this.summariesWithTag.map((record) => record.ai_prompt_group_id)),
      ];

      const uniquePromptGroups = uniquePromptGroupIds.map((id) => ({
        id,
        name: this.summariesWithTag.find((record) => record.ai_prompt_group_id === id)
          .ai_prompt_group,
      }));

      const allDates = this.summariesWithTag.map((record) => record.datetime.split("T")[0]);
      const uniqueDates = this.orderByDate([...new Set(allDates)]);

      // Create datasets for each prompt group
      const datasets = uniquePromptGroups.map(({ id, name }) => {
        const promptGroupDataForId = this.groupDataForPromptGroup(
          this.summariesWithTag,
          id,
          this.aiEngineModel.model_type,
          this.aiTag.ai_prompt_template_tag_id,
          uniqueDates
        );

        let borderWidth = uniqueDates.length === 1 ? 5 : 2;
        let pointRadius = uniqueDates.length === 1 ? 3 : 5; 

        // If there's only one date, make sure it appears as a single point
        if (uniqueDates.length === 1) {
          return {
            label: name, // Use the name of the prompt group as the label
            data: [Object.values(promptGroupDataForId)[0]], // Use a single value for the data array
            fill: false,
            borderColor: `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 1)`,
            borderWidth: borderWidth,
            pointRadius: pointRadius,
            pointBackgroundColor: `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 1)`,
          };
        } else {
          return {
            label: name, // Use the name of the prompt group as the label
            data: Object.values(promptGroupDataForId),
            fill: false,
            borderColor: `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 1)`,
            borderWidth: borderWidth,
            pointRadius: pointRadius,
            lineTension: 0.4,
            pointBackgroundColor: `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 1)`,
          };
        }
      });

      return {
        labels: uniqueDates,
        datasets: datasets,
      };
    },

  },
  watch: {
    summariesWithTag(currentValue) {
      if (currentValue?.length) {
        this.chartDataForTag = this.prepareChartDataForTag();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";

.chart-peer-compare {
  display: flex;
  width: 546px;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  border-radius: 20px;
  border: 1px solid var(--grey2);
  background: var(--white);

  .title {
    color: var(--grey9);
    @include h3-style;
  }
}
</style>
