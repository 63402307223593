
function sortSummariesByTagFrequency(summaries) {
  // Calculate tag frequency
  const tagFrequency = {};
  summaries.forEach((summary) => {
    summary.tags.forEach((tag) => {
      const tagString = tag.tag;
      tagFrequency[tagString] = (tagFrequency[tagString] || 0) + 1;
    });
  });

  // Create a new array and sort it based on tag frequency
  const sortedSummaries = [...summaries].sort((a, b) => {
    const frequencyA = a.tags.reduce((acc, tag) => acc + (tagFrequency[tag.tag] || 0), 0);
    const frequencyB = b.tags.reduce((acc, tag) => acc + (tagFrequency[tag.tag] || 0), 0);

    return frequencyB - frequencyA; // Sort in descending order
  });

  return sortedSummaries;
}

function getSentiment(score, neutralThreshold) {
  if (score == null) {
    return 'no-sentiment';
  }

  if (score < neutralThreshold) {
    return 'negative';
  } else if (score === neutralThreshold) {
    return 'neutral';
  } else {
    return 'positive';
  }
}

/**
 * @param { string } url 
 * @returns { string } domain
 */
function extractDomain(url) {
  const self = this.extractDomain;
  self.re ||= {
    urlToDomain: /\/\/([^/()"' ?]+)(?:\/|$|\?)/,
    tld2PartDomains: /\.(?:org|net|com|gov|int|ch|de|fr|pt|ie|it|nl|be)$/,
    tld2Parts: /([^.]+\.[^.]+)$/,
    tld3Parts: /((?:[^.]+\.)?[^.]+\.[^.]+)$/,
  };

  if (url === '' || typeof url != 'string') {
    return null;
  }
  url = url.replace('//www.', '//');

  let match = url.match(self.re.urlToDomain);
  if (url.match(self.re.urlToDomain) === null) {
    url = '//' + url;
    match = url.match(self.re.urlToDomain);
    if (match === null) {
      return null;
    }
  }

  let domain = match[1];

  const tldRE = domain.match(self.re.tld2PartDomains) && !domain.endsWith('.go.com')
    ? self.re.tld2Parts
    : self.re.tld3Parts;

  match = domain.match(tldRE);
  if (match !== null) {
    domain = match[1];
  }

  return domain;
}

function stylizeTextBasedFormatting(content) {
  if (!content) return '';

  let formatted = content;
  formatted = content.replaceAll(new RegExp("(nn.)", "gmi"), "<p></p>");

  formatted = content.replaceAll(new RegExp("\\*\\*([^\\n\\t\\*]{1,30})\\*\\*", "gmi"), "<b>$1</b>");

  return formatted;
}

function escapeRE(s) {
  return s.replaceAll(/(\W)/g, "\\$1");
}

function getFaviconUrl(domain) {
  const faviconPath = "https://web-images.fiveblocks.com/favicon";
  return [faviconPath, domain.slice(0, 2), domain].join("/") + "_favicon.ico";
}

export default {
  sortSummariesByTagFrequency,
  getSentiment,
  extractDomain,
  stylizeTextBasedFormatting,
  escapeRE,
  getFaviconUrl,
};