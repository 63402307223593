<template>
  <div class="app-logo-container" :class="[theme, size]">
    <div class="main-line">
      <img :width="logoSize" :height="logoSize" src="@/assets/images/fiveblocks-logo.svg" alt="fiveblocks" />
      <span class="fb-wrapper"><span class="five">five</span><span class="blocks">blocks</span></span>
      <div class="product-name">AIQ</div>
    </div>
  </div>
</template>

<script>

export default {
  name: "AppLogo",
  props: {
    theme: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      required: true,
    }
  },
  components: {
  },
  computed: {
    color() {
      return {
        light: 'white',
        dark: 'black',
      }[this.theme];
    },

    logoSize() {
      return {
        small: 32,
        large: 46,
      }[this.size];
    },
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.app-logo-container {
  text-align: left;

  &.large {
    --fb-offset: 5px;
  }

  &.small {
    --fb-offset: 1px;
  }

  &.light {
    color: white;

    .beta-logo {
      color: white;
      background-color: var(--chart-blue9);
    }
  }

  &.dark {
    color: black;

    .beta-logo {
      color: white;
      background-color: black;
    }
  }

  &.large {
    .main-line {
      font-size: 36px;
    }

  }

  &.small {
    .main-line {
      font-size: 24px;
    }
  }
}

.main-line {
  display: flex;
  align-items: center;
  font-weight: 500;
}

img {
  margin: 0 3px;
}

.fb-wrapper {
  margin-right: .3rem;
  display: flex;
  align-items: center;

  font-weight: 500;

  .five {
    font-weight: bold;
    font-stretch: 90%;
  }

  .blocks {
    font-stretch: 90%;
  }
}
</style>
