<template>
  <summary-wrapper :groupedSummaries="groupedSummaries"
                   :groupedResponses="groupedResponses"
                   :routeback="route.entityTracker"
                   :loading="loadingData"
                   :isTimeline="true">

    <summary-options :field-labels="true">
      <date-range />
      <ai-models-dropdown />
    </summary-options>

    <template #no-data-message>There is no data for the selected {{ this.noData ? "date range" : "model" }}.</template>

  </summary-wrapper>
</template>

<script>
import SummaryWrapper from "@/components/page-elements/SummaryWrapper.vue";
import SummaryOptions from "@/components/page-elements/SummaryOptions.vue";
import DateRange from "@/components/form-elements/DateRange.vue";
import AiModelsDropdown from "@/components/form-elements/AiModelsDropdown.vue";
import { ROUTE } from "@/constants";


export default {
  props: ["groupedSummaries", "groupedResponses", "noData", "loadingData"],
  data() {
    return {
      route: ROUTE,
      maxSummaryHeights: null,
      reportedHeights: [],
    };
  },
  components: {
    SummaryWrapper,
    SummaryOptions,
    DateRange,
    AiModelsDropdown,
  },
};
</script>
