<template>
  <div class="top-tags-section">
    <table class="mt-1">
      <tr v-for="(tag, index) in topTags" :key="tag.tag" class="tag-item">
        <td class="tag-number">{{ index + 1 }}</td>
        <td class="tag-name" @click="handleTagButtonClick(tag)">#{{ tag.tag }}</td>
        <td class="tag-amount">{{ tag.frequency }}</td>
        <td class="tag-bar" :style="{ width: tagWidth(tag) + 'px' }" />
      </tr>
    </table>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { ROUTE } from "@/constants";
export default {
  props: {
    topTags: {
      type: Array,
      required: true,
    },
    routeback: {
      required: true,
    },
  },
  data() {
    return {
      route: ROUTE,
    }
  },
  methods: {
    ...mapActions("ai", ["setAiTag"]),
    async handleTagButtonClick(tag) {
      await this.setAiTag(tag);
      this.$router.push({ path: this.route.tagDrilldown + this.routeback });
    },
  },
  computed: {
    tagWidth() {
      // Find the maximum frequency among tags
      const maxFrequency = Math.max(...this.topTags.map(tag => tag.frequency));

      // Set a maximum width for the tag bar
      const maxWidth = 110;

      // Calculate the width for a specific tag based on its frequency and the maximum width
      return tag => Math.round((tag.frequency / maxFrequency) * maxWidth);
    },

  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";

.top-tags-section {

  .tag-number {
    margin-right: 8px;
    min-width: 20px;
    color: var(--grey6);
    @include body3;
  }

  .tag-name {
    padding-right: .5rem;
    color: var(--primary-blue7);
    @include tag2-style;
    text-decoration-line: underline;
    cursor: pointer;
    min-width: 9rem;
  }

  .tag-amount {
    padding-right: .5rem;
    width: calc((min(100vw, 1400px)) / 18);
    color: var(--grey9);
    @include body3;
  }

  .tag-bar {
    display: flex;
    width: 117px;
    height: 15px;
    padding-left: 12px;
    border-radius: 0px 12px 12px 0px;
    background: var(--chart-blue6);
    margin: .4rem 0;
  }
}
</style>
