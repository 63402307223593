<template>
  <slot />


  <div v-if="!groupedSummaries?.length && !loading" class="no-data">
    <slot name="no-data-message" />
  </div>

  <div v-else class="summary-view">
    <div v-for="(record, index) in groupedSummaries" :key="index">
      <summary-card :date-header="record.key"
                    :grouped-summary="record.summaries"
                    :grouped-responses="groupedResponses"
                    :routeback="routeback"
                    :max-summary-heights="maxSummaryHeights"
                    @update-max-summary-heights="updateMaxSummaryHeights"
                    class="summary-card fit-card"
                    :class="{
                      'has-4': groupedSummaries.length >= 4,
                      'has-5': groupedSummaries.length >= 5,
                      'scroll-into-view': isTimeline && index === groupedSummaries.length - 1
                    }" />
    </div>
  </div>
</template>

<script>
import SummaryCard from "@/components/card-elements/SummaryCard.vue";
import useRouteHelper from "@/composables/useRouteHelper";

export default {
  props: [
    "loading",
    "groupedSummaries",
    "groupedResponses",
    "routeback",
    "isTimeline",
  ],
  data() {
    return {
      maxSummaryHeights: null,
      reportedHeights: [],
    };
  },
  components: {
    SummaryCard,
  },
  computed: {
  },
  methods: {
    updateMaxSummaryHeights(heights) {
      const extremeHeightDiff = 180;

      // gather the reported section heights from the child components (individual cards)
      this.reportedHeights.push(heights);
      if (this.reportedHeights.length == this.groupedSummaries.length) {
        // after all cards have reported in, figure out the maxs for each of the 3 card sections
        this.maxSummaryHeights = Array(3).fill(0);
        this.reportedHeights.forEach(reportedHeightSet => {
          this.maxSummaryHeights = this.maxSummaryHeights.map((max, index) => Math.max(max, reportedHeightSet[index]));
        });

        const secondTallestTopSection = this.reportedHeights.map(reportedHeightSet => reportedHeightSet[0]).sort((a, b) => b - a)[1];

        if (this.maxSummaryHeights[0] > secondTallestTopSection + extremeHeightDiff) {
          this.maxSummaryHeights[0] = secondTallestTopSection;
        }

        this.reportedHeights = [];
      }
    },
  },
  setup() {
    const { routeTo } = useRouteHelper();
    return {
      routeTo,
    };
  },
  watch: {
    groupedSummaries() {
      this.maxSummaryHeights = null;
    },
  },
};

</script>

<style lang="scss" scoped>
.summary-view {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 1rem;
  gap: var(--card-spacing);

  &>div {
    flex: 1;
    min-width: 0;
  }

  .summary-card {
    min-width: 15rem;
    max-width: 25rem;
  }

  .fit-card {
    --cards-to-fit: 3;
    --indicate-more-buffer: 0rem;

    &.has-4 {
      --cards-to-fit: 4;
    }
    &.has-5 {
      --indicate-more-buffer: 1rem;
    }

    width: calc((100vw - var(--gutter-width) - var(--side-menu-width) - ((var(--card-spacing) * (var(--cards-to-fit) - 1))) - 3rem - var(--indicate-more-buffer)) / var(--cards-to-fit));
  }
}

.no-data {
  margin-top: 1rem;
}
</style>
