<template>
  <div class="card-header-outer-wrapper" :class="{ 'is-today': isToday && highlightToday }">
    <div class="card-header-wrapper d-flex">
      <div class="header d-flex justify-content-between">
        <div v-if="groupedData" class="header-item d-flex">
          <span :class="'icons ' + modelType" />
          <span class="ms-1">{{ getModelName(modelType) }}</span>
        </div>


        <div class="d-flex flex-column details">
          <div>
            <span class="primary-detail">{{ name }}</span>
          </div>

          <div>
            <span>{{ formattedDate }}</span>
          </div>

        </div>

      </div>

      <i v-if="showOpenAll" @click="$emit('openAllClick')"
         class="open-all-handle ms-3 bi"
         :class="'bi-chevron-double-' + (showOpenAll == 'up' ? 'up' : 'down')" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { AiModelDisplayNames } from "@/constants";

export default {
  props: ['dateHeader', 'groupedData', 'showOpenAll'],
  emits: ['openAllClick'],
  computed: {
    ...mapGetters("general", {
      view: "view",
    }),
    ...mapGetters("ai", {
      aiPromptGroup: "aiPromptGroup",
    }),

    dateObject() {
      const datePart = this.dateHeader.includes("-")
        ? this.dateHeader.split("-")[0]
        : this.dateHeader;

      return this.$dateTime.fromFormat(datePart, "MM/dd/yyyy");
    },
    formattedDate() {
      return this.dateObject.toFormat("dd LLL yyyy"); // Format as "19 Dec 2023"
    },
    isToday() {
      return this.dateObject.hasSame(this.$dateTime.now(), "day");
    },
    highlightToday() {
      return this.view == 'entityTracker';
    },
    modelType() {
      return this.groupedData.length ? this.groupedData[0].model_type : "";
    },
    name() {
      const isPeer = this.groupedData?.[0]?.ai_prompt_group !== this.aiPromptGroup.name;
      return isPeer ? this.groupedData?.[0]?.ai_prompt_group : this.aiPromptGroup.display_name;
    },
  },
  methods: {
    getModelName(modelType) {
      return AiModelDisplayNames[modelType];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_icons.scss";
@import "@/scss/_mixins.scss";

.card-header-outer-wrapper {
  position: sticky;
  top: 0;
  z-index: calc(var(--z-sticky-baseline) + 1);
  background-color: var(--grey1);
  border: solid 2px var(--primary-blue2);
  box-shadow: 0 1px 1px var(--grey4);
  border-radius: 16px 16px 0px 0px;

  .card-header-wrapper {
    padding: .5rem .7rem;
    border-radius: 12px 12px 0px 0px;
    background: var(--primary-blue2);
    font-weight: 500;
    align-items: center;
  }
}

.card-header-outer-wrapper.is-today {
  padding: .2rem;
  border-color: var(--primary-blue7);
  box-shadow: 0 1px 3px var(--primary-blue6);

  .card-header-wrapper {
    padding: .3rem .6rem;
    color: var(--primary-blue7);
  }
}

.header {
  flex-grow: 1;
  align-items: center;
  justify-content: center;

  .header-item {
    color: var(--primary-blue7);
  }

  .icons {
    align-self: center;
  }

  .details {
    font-size: smaller;
    color: var(--grey7);
    align-items: flex-end;

    .primary-detail {
      color: var(--grey9);
      font-weight: 600;
    }
  }

  .open-all-handle:hover {
    color: var(--primary-blue5);
  }
}
</style>