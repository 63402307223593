<template>
  <div>
    <response-options>
      <date-selector :show-label="false" class="date-selector" />
    </response-options>

    <div v-if="noData" class="no-data">There is no data for the selected date range.</div>
    <div v-else-if="!groupedResponses?.length && !loadingData" class="no-data">
      There is no data for the selected model.
    </div>
    <!-- lower section -->
    <div class="justify-content-between d-flex mt-3">
      <div class="response-view">
        <response-card v-for="(groupedResponse) in orderByModel(groupedResponses)" :key="groupedResponse.key"
                       :routeback="route.modelsCompare"
                       :grouped-response="groupedResponse" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ResponseCard from "@/components/card-elements/ResponseCard.vue";
import DateSelector from "@/components/form-elements/DateSelector.vue";
import { AiModelDisplayNames } from "@/constants";
import ResponseOptions from "@/components/page-elements/ResponseOptions.vue";
import { ROUTE } from "@/constants";

export default {
  props: ["groupedResponses", "noData", "loadingData"],
  data() {
    return {
      route: ROUTE,
    };
  },
  components: {
    ResponseCard,
    DateSelector,
    ResponseOptions,
  },
  methods: {
    ...mapActions("ai", [
      "setAiTag",
      "setAiEngineModel",
      "setAiPromptGroup",
    ]),
    orderByModel(groupedResponses) {
      const modelArr = Object.keys(AiModelDisplayNames)
      const ordered = groupedResponses.sort((a, b) => {
        return modelArr.indexOf(a.responses[0].model_type) - modelArr.indexOf(b.responses[0].model_type);
      });
      return ordered;
    },
  },
  computed: {
    ...mapGetters("ai", {
      aiEngineModels: "aiEngineModels",
      aiPromptGroup: "aiPromptGroup",
      aiPromptGroups: "aiPromptGroups",
      selectedPrompt: "selectedPrompt",
    }),

    selectedAiPromptGroup: {
      get() {
        return this.$store.state.ai.aiPromptGroup;
      },
      set(value) {
        this.setAiPromptGroup(value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";
@import "@/scss/icons.scss";

.response-view {
  display: flex;
  align-items: stretch;
  gap: var(--card-spacing);
  flex-shrink: 0;
}

.no-data {
  margin-top: 1rem;
}
</style>
