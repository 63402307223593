<template>
  <div class="chart-frequency-by-tag" v-if="summariesWithTag.length">
    <span class="title">How frequently does #{{ aiTag.tag.toLowerCase() }} appear for {{ modelType }}?</span>
    <div class="chart-container" v-if="chartDataForTag?.labels">
      <Line :data="chartDataForTag" :options="chartOptionsForTag" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { AiModelDisplayNames } from "@/constants";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "vue-chartjs";
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default {
  data() {
    return {
      chartDataForTag: [],
    };
  },
  components: {
    Line,
  },
  props: {
    summariesWithTag: Array,
  },
  computed: {
    ...mapGetters("ai", {
      aiTag: "aiTag",
      aiPromptGroup: "aiPromptGroup",
      aiEngineModel: "aiEngineModel",
    }),
    modelType() {
      return AiModelDisplayNames[this.aiEngineModel.model_type];
    },
    chartOptionsForTag() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: "bottom", // Move legend to the bottom
            labels: {
              fontColor: "#3C4149",
            },
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            beginAtZero: true,
          },
        },
        onClick: this.handleChartClick
      };
    },
  },
  methods: {
    orderByDate(groupedData) {
      const orderedData = Object.entries(groupedData).sort(([a], [b]) => {
        if (a === b) return 0;
        if (a < b) return -1;
        else return 1;
      });
      return Object.fromEntries(orderedData);
    },
    handleChartClick(e, activeEls) {
      try {
        if (activeEls && activeEls.length > 0) {
          let datasetIndex = activeEls[0].datasetIndex;
          let dataIndex = activeEls[0].index;
          let datasetLabel = e.chart.data.datasets[datasetIndex].label;
          let value = e.chart.data.datasets[datasetIndex].data[dataIndex];
          let label = e.chart.data.labels[dataIndex];
          console.log("In click", datasetLabel, label, value);
        } else {
          console.error("No active elements found.");
        }
      } catch (error) {
        console.error("Error handling chart click:", error);
      }
    },
    groupDataForPromptGroup(data, promptGroupId, modelType, tagId, uniqueDates) {

      const groupedData = uniqueDates.reduce((result, date) => {
        let recordsForDate = [];
        if (modelType == 'all') {
          recordsForDate = data.filter(
            (record) =>
              record.ai_prompt_group_id === promptGroupId &&
              record.tags.some((tag) => tag.ai_prompt_template_tag_id === tagId) &&
              record.datetime.split("T")[0] === date
          );
        } else {

          recordsForDate = data.filter(
            (record) =>
              record.ai_prompt_group_id === promptGroupId &&
              record.model_type === modelType &&
              record.tags.some((tag) => tag.ai_prompt_template_tag_id === tagId) &&
              record.datetime.split("T")[0] === date
          );
        }

        result[date] = recordsForDate.length;
        return result;
      }, {});

      return this.orderByDate(groupedData);
    },

    groupDataForPeer(data, promptGroupId, modelType, tagId, uniqueDates) {
      const groupedData = uniqueDates.reduce((result, date) => {
        let recordsForDate = [];
        if (modelType == 'all') {
          recordsForDate = data.filter(
            (record) =>
              record.ai_prompt_group_id !== promptGroupId &&
              record.tags.some((tag) => tag.ai_prompt_template_tag_id === tagId) &&
              record.datetime.split("T")[0] === date
          );
        } else {
          recordsForDate = data.filter(
            (record) =>
              record.ai_prompt_group_id !== promptGroupId &&
              record.model_type === modelType &&
              record.tags.some((tag) => tag.ai_prompt_template_tag_id === tagId) &&
              record.datetime.split("T")[0] === date
          );
        }

        result[date] = recordsForDate.length;
        return result;
      }, {});

      return this.orderByDate(groupedData);
    },
    prepareChartDataForTag(promptGroupData, peerData) {
      let labels = Object.keys(promptGroupData);
      let promptGroupValues = Object.values(promptGroupData);
      let peerValues = Object.values(peerData);

      // If there's only one label, make sure it appears as a single point
      let borderWidth = labels.length === 1 ? 5 : 2;
      let pointRadius = labels.length === 1 ? 3 : 5; // Adjust the point radius

      return {
        labels: labels,
        datasets: [
          {
            label: this.aiPromptGroup.name,
            data: promptGroupValues,
            fill: false,
            borderColor: "rgba(75, 192, 192, 1)",
            borderWidth: borderWidth,
            pointBackgroundColor: "rgba(75, 192, 192, 1)",
            pointRadius: pointRadius,
            lineTension: 0.4,
          },
          {
            label: "Peer avg",
            data: peerValues,
            fill: false,
            borderColor: "rgba(192, 75, 75, 1)", // Change color if needed
            borderWidth: borderWidth,
            pointBackgroundColor: "rgba(192, 75, 75, 1)", // Change color if needed
            pointRadius: pointRadius,
            lineTension: 0.4,
          },
        ],
      };
    },
  },
  watch: {
    summariesWithTag(currentValue) {
      if (currentValue?.length) {
        const allDates = currentValue.map((record) => record.datetime.split("T")[0]);
        const uniqueDates = [...new Set(allDates)];

        const promptGroupData = this.groupDataForPromptGroup(
          currentValue,
          this.aiPromptGroup.id,
          this.aiEngineModel.model_type,
          this.aiTag.ai_prompt_template_tag_id,
          uniqueDates
        );

        const peerData = this.groupDataForPeer(
          currentValue,
          this.aiPromptGroup.id,
          this.aiEngineModel.model_type,
          this.aiTag.ai_prompt_template_tag_id,
          uniqueDates
        );

        this.chartDataForTag = this.prepareChartDataForTag(this.orderByDate(promptGroupData), peerData);
      }
    },
  },

};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";

.chart-frequency-by-tag {
  display: flex;
  width: 546px;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  border-radius: 20px;
  border: 1px solid var(--grey2);
  background: var(--white);

  .title {
    color: var(--grey9);
    @include h3-style;
  }
}
</style>
