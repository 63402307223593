<template>
  <div class="multiselect" v-click-outside="onClickOutside"  @click="handleClickInsideComponent">
    <span class="no-data" v-if="filteredOptions.length === 0">{{ noData }}</span>
    <!-- Selected tags -->
    <div class="selected-tags">
      <div v-for="option in selectedOptions" :key="getOptionKey(option)" class="tag">
        <span class="tag-label">{{ getOptionLabel(option) }}</span>
        <span @click="(e) => removeSelected(e, option)" class="icons close-small remove-tag" />
      </div>
    </div>

    <!-- Dropdown -->
    <div v-show="isDropdownOpen && filteredOptions.length > 0" class="dropdown">
      <div class="icons close-icon" @click="closeDropdown">✕</div>

      <!-- Select All checkbox -->
      <div class="select-all" v-show="filteredOptions.length > 0">
        <input type="checkbox" id="selectAll" v-model="selectAll" @change="toggleSelectAll" />
        <label for="selectAll">Select All</label>
      </div>

      <!-- Options -->
      <div class="options">
        <div class="option" v-for="option in filteredOptions" :key="getOptionKey(option)">
          <span class="icons two-lines" />
          <input
            type="checkbox"
            :id="getOptionValue(option)"
            :value="getOptionValue(option)"
            v-model="getSelectedState()[getOptionValue(option)]"
            @change="toggleOption(option)"
          />
          <label
            :for="getOptionValue(option)"
            :class="{ selected: getSelectedState()[getOptionValue(option)] }"
          >
            {{ getOptionLabel(option) }}
          </label>
        </div>
      </div>
    </div>
    <div
      class="icons dropdown-arrow"
      :class="{ 'arrow-up': isDropdownOpen }"
      @click="handleArrowClick"
    />
  </div>
</template>

<script>
import vClickOutside from "click-outside-vue3";
export default {
  emits: ["input"],
  props: ["options", "labelProp", "valueProp", "noData", "value"],
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      selectedOptions: [],
      selectAll: false,
      search: "",
      isDropdownOpen: false,
    };
  },
  computed: {
    filteredOptions() {
      return this.options.filter((option) =>
        this.getOptionLabel(option).toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  watch: {
    value: {
      handler: function (currentValue) {
        this.selectedOptions = [...currentValue];
        this.updateSelectAll();
      },
      immediate: true,
    },
  },

  methods: {
    getOptionKey(option) {
      return option[this.valueProp];
    },
    getOptionValue(option) {
      return option[this.valueProp];
    },
    getOptionLabel(option) {
      return option[this.labelProp];
    },
    getOptionSelected(option) {
      return this.selectedOptions.some(
        (selected) => this.getOptionKey(selected) === this.getOptionKey(option)
      );
    },
    removeSelected(event, option) {
      event.stopPropagation();
      this.selectedOptions = this.selectedOptions.filter(
        (selected) => this.getOptionKey(selected) !== this.getOptionKey(option)
      );

      this.updateSelectAll();
      this.$emit("input", this.selectedOptions);
    },
    updateSelectAll() {
      this.selectAll =
        this.filteredOptions.length > 0 && this.filteredOptions.every(this.getOptionSelected);
    },
    toggleOption(option) {
      const selectedKey = this.getOptionKey(option);

      if (this.getOptionSelected(option)) {
        this.selectedOptions = this.selectedOptions.filter(
          (selected) => this.getOptionKey(selected) !== selectedKey
        );
      } else {
        this.selectedOptions.push(option);
      }

      this.updateSelectAll();
      this.$emit("input", this.selectedOptions);
    },

    toggleSelectAll() {
      const allOptionsSelected = this.filteredOptions.every(this.getOptionSelected);

      this.options.forEach((option) => {
        const isSelected = this.selectedOptions.includes(option);

        if (!allOptionsSelected && !isSelected) {
          this.selectedOptions.push(option);
        } else if (allOptionsSelected && isSelected) {
          const index = this.selectedOptions.indexOf(option);
          this.selectedOptions.splice(index, 1);
        }
      });

      this.selectAll = !allOptionsSelected;
      this.$emit("input", this.selectedOptions); // Emit input event to sync with v-model in the parent
    },

    getSelectedState() {
      return Object.fromEntries(
        this.options.map((option) => [
          option[this.valueProp],
          this.selectedOptions.some(
            (selected) => selected[this.valueProp] === option[this.valueProp]
          ),
        ])
      );
    },

    openDropdown() {
      this.isDropdownOpen = true;
    },

    toggleDropdown() {
      if (this.filteredOptions.length > 0) {
        this.isDropdownOpen = !this.isDropdownOpen;
      }
    },
    handleArrowClick(event) {
      event.stopPropagation();
      this.toggleDropdown();
    },

    closeDropdown(event) {
      event.stopPropagation();
      this.isDropdownOpen = false;
    },
    onClickOutside() {
      this.isDropdownOpen = false;
    },
    handleClickInsideComponent(event) {
      const isClickInsideComponent = this.$el.contains(event.target);
      const isClickOnSelectedTags = event.target.closest('.selected-tags');
      const isClickOnDropdown = event.target.closest('.dropdown');
      const isClickOnArrow = event.target.classList.contains('dropdown-arrow');

      if (isClickInsideComponent && !isClickOnSelectedTags && !isClickOnDropdown && !isClickOnArrow) {
        this.toggleDropdown();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";
@import "@/scss/icons.scss";

.multiselect {
  display: flex;
  padding: 0.2rem 1rem;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 1.875rem;
  border: 1px solid var(--grey3);
  background: #fff;

  position: relative;
  min-width: 25rem;
  height: 2.9rem;
  .no-data {
    color: var(--grey7);
    @include body3;
  }
  .dropdown-arrow {
    cursor: pointer;
  }
}

.selected-tags {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tag {
  display: flex;
  padding: 0.25rem 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 1.25rem;
  background: var(--primary-blue2);
  color: var(--primary-blue5);

  @include body3;
  cursor: pointer;
  .tag-label {
    height: 1.4rem;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 3.5rem;
    margin: auto 0;
  }
}

.remove-tag {
  margin-left: 4px;
  cursor: pointer;
}

.dropdown {
  position: absolute;
  z-index: var(--z-popover);
  top: 3rem;
  left: 0;
  width: 100%;
  padding: 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 1.25rem;
  border: 1px solid var(--grey3);
  background: #fff;
  .options {
    display: flex;
    padding: 0;
    align-items: center;
    align-self: stretch;
    flex-direction: column;
    .option {
      display: flex;
      padding: 0.75rem 1rem;
      align-items: center;
      gap: 0.625rem;
      align-self: stretch;
      align-items: center;
      label {
        color: var(--grey7);
        cursor: pointer;
        @include body3;
      }

      input:checked + label {
        color: var(--grey9);
      }
      &:hover {
        background: var(--primary-blue1);
        &:last-child {
          border-bottom-left-radius: 1.25rem;
          border-bottom-right-radius: 1.25rem;
        }
      }
    }
  }
  .select-all {
    display: flex;
    padding: 0.75rem 1rem;
    align-items: center;
    gap: 0.625rem;
    align-self: stretch;

    label {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: var(--grey9);
      @include body3;
      cursor: pointer;
    }
  }
}

.close-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  font-size: 18px;
  color: var(--grey7);
}
</style>
