<template>
  <div class="engines">
    <div class="engine-button" v-for="(engine, index) in modelsArray(models)" :key="index">
      <span :class="'icons ' + engine"></span>
      <span class="engine-text">{{ getModelName(engine) }}</span>
    </div>
  </div>
</template>

<script>
import { AiModelDisplayNames } from "@/constants";

export default {
  props: ["models"],

  data() {
    return {};
  },
  components: {},
  methods: {
    modelsArray(enginesStr) {
      const engines = enginesStr?.split(", ");
      const modelArr = Object.keys(AiModelDisplayNames);
      return engines.sort((a, b) => modelArr.indexOf(a) - modelArr.indexOf(b));
    },
    getModelName(modelType) {
      return AiModelDisplayNames[modelType];
    },
  },
  computed: {},
  watch: {},
  mounted() { },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";
@import "@/scss/icons.scss";

.engines {
  display: flex;
  flex-wrap: wrap;
  max-width: 20rem;
  gap: .3rem;

  .engine-button {
    display: flex;
    padding: 0px 5px;
    gap: 8px;
    width: min-content;

    border-radius: 40px;
    background: var(--grey1);

    .icons {
      align-self: center;
    }
    .engine-text {
      color: var(--grey9);
      @include body3;
      white-space: nowrap;
      min-width: 5.5rem;
    }
  }
}
</style>
