<template>
  <div class="response-item">

    <template v-if="response.content">

      <Tags :response="response" @tagHover="handleTagHover" :routeback="routeback" class="tags"
            :shouldHighlightOnHover="true" />

      <span class="content" :class="{ 'show-more': showMore }"
            v-html="formattedAndHighlightedContent">
      </span>

      <sources class="mt-3 ms-3" :prompts="[response]" />

    </template>

    <span v-else class="no-response">
      <span class="icons alert-icon"></span>
      <strong>Prompt adjustment needed</strong>
      <div>
        {{ modelType }} does not respond to this prompt. Please consider revising the prompt from the
        <a class="manage-page-link" @click="routeTo('/promptGroups')">manage page</a>
        to generate a response from {{ modelType }}.
      </div>
    </span>
  </div>
</template>

<script>
import Tags from "@/components/form-elements/tags/index.vue";
import { AiModelDisplayNames } from "@/constants";
import Sources from "@/components/form-elements/Sources.vue";
import { mapGetters } from "vuex";
import aiUtil from "@/utils/aiUtil.js";
import useRouteHelper from "@/composables/useRouteHelper";

export default {
  props: ["response", "routeback"],
  data() {
    return {
      CONTENT_MAX: 850,
      showMore: false,
      highlightedContent: "",
      highlightTag: null,
    };
  },
  components: {
    Tags,
    Sources
  },
  computed: {
    ...mapGetters("ai", {
      neutralThreshold: "neutralThreshold",
      showSentiment: "showSentiment"
    }),
    modelType() {
      return AiModelDisplayNames[this.response.model_type];
    },
    formattedAndHighlightedContent() {
      const formattedContent = aiUtil.stylizeTextBasedFormatting(this.highlightedContent);
      return this.highlightMatchingTag(formattedContent);
    }
  },
  methods: {
    handleTagHover(tag) {
      this.highlightTag = tag;
    },
    highlightMatchingTag(prompt) {
      // Copy the content to avoid modifying the original prompt
      let highlightedContent = prompt;

      if (this.highlightTag?.phrase) {
        const regex = new RegExp(aiUtil.escapeRE(this.highlightTag.phrase), "gi");
        highlightedContent = highlightedContent.replace(
          regex,
          (match) => `<span class="matching-phrase">${match}</span>`
        );
      }

      return highlightedContent;
    },
    toggleShowMore() {
      this.showMore = !this.showMore;
    },
    highlightMatchingPhrases() {
      const content = this.response.content;
      const sentiments = this.response.sentiments;
      this.highlightedContent = content;

      if (!content || !sentiments) return;

      if (this.showSentiment) {
        sentiments.forEach(phrase => {
          const regex = new RegExp(aiUtil.escapeRE(phrase.phrase), "gi");
          this.highlightedContent = this.highlightedContent.replace(
            regex,
            `<span class="${aiUtil.getSentiment(phrase.score, this.neutralThreshold)}-text">$&</span>`
          );
        });
      }
    }
  },
  mounted() {
    this.highlightMatchingPhrases();
  },
  watch: {
    showSentiment() {
      this.highlightMatchingPhrases();
    },
    neutralThreshold() {
      this.highlightMatchingPhrases();
    },
    response() {
      this.highlightMatchingPhrases();
    },
  },
  setup() {
    const { routeTo } = useRouteHelper();
    return {
      routeTo,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";
@import "@/scss/icons.scss";


.response-item {
  overflow: hidden;
  color: var(--grey8);
  @include body3;

  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  .no-response {
    .alert-icon {
      display: inline-block;
      margin-right: .5rem;
    }

    .alert-icon,
    strong {
      vertical-align: middle;
    }

    div {
      margin-top: 1rem;
    }
  }

  .show-content {
    cursor: pointer;
    color: var(--primary-blue5);
    text-align: left;
    @include link1-style;
  }

  .content {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;

    white-space: break-spaces;


    &.show-more {
      -webkit-line-clamp: unset;
    }
  }

  :deep(.negative-text) {
    background-color: #ffa5a5;
  }

  &.response-item-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    width: 100%;

    .model-type {
      color: var(--grey9);
      @include h4-style;
    }
  }

  .domain-container {
    display: flex;
    flex-wrap: wrap;

    .label {
      @include h4-style;
      color: var(--grey9);
      margin: auto 8px auto 0;
    }

    .domain {
      padding: 4px 8px;
      align-items: center;
      gap: 8px;
      margin-right: 8px;
      border-radius: 20px;
      background: var(--primary-blue2);

      span {
        color: var(--primary-blue5);
        @include body3;
      }
    }
  }

  :deep(.matching-phrase) {
    background-color: yellow !important;
    transition: background-color 0.3s ease;
  }

  .manage-page-link {
    cursor: pointer;
  }
}
</style>