<template>
  <div v-if="prompts?.length" class="rounded-curve-dropdown d-flex flex-column">
    <prompt-dropdown :uniqueAiPrompts="uniqueFilteredPrompts" @select-prompt="handleSelectPrompt"
      :selectedPromptName="selectedPromptName" />
    <div v-if="selectedPrompt">
      <ul class="related-list mt-3">
        <li v-for="(relatedPrompt, index) in relatedPrompts" :key="index" class="related-list-item">
          <div class="d-flex">
            <span :class="'icons ' + relatedPrompt.model_type"></span>
            <div class="d-flex flex-column">
              <Tags :response="relatedPrompt" @tagHover="handleTagHover" :routeback="routeback"  class="mb-1"
                :shouldHighlightOnHover="true" />
              <div class="related-list-text" v-html="highlightMatchingPhrase(relatedPrompt)"></div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div v-else>
    <span>Reference information not available.</span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Tags from "@/components/form-elements/tags/index.vue";
import PromptDropdown from "@/components/form-elements/PromptDropdown.vue";

export default {
  props: ["prompts", "routeback"],
  data() {
    return {
      selectedPrompt: null,
      selectedPromptName: null,
      highlightTag: null,
    };
  },
  components: {
    Tags,
    PromptDropdown,
  },
  methods: {
    handleTagHover(tag) {
      this.highlightTag = tag;
    },
    highlightMatchingPhrase(prompt) {
      // Copy the content to avoid modifying the original prompt
      let highlightedContent = prompt.content;

      if (this.highlightTag?.phrase) {
        const regex = new RegExp(this.highlightTag.phrase, "gi");
        highlightedContent = highlightedContent.replace(
          regex,
          (match) => `<span class="matching-phrase">${match}</span>`
        );
      }

      return highlightedContent;
    },
    handleSelectPrompt(value) {
      const selectedPromptObject = this.uniqueFilteredPrompts.find(obj => obj.ai_prompt === value);
      if (selectedPromptObject) {
        this.selectedPrompt = selectedPromptObject.ai_prompt_id;
        this.selectedPromptName = selectedPromptObject.ai_prompt;
      }
    },
  },
  computed: {
    ...mapGetters("ai", {
      aiEngineModel: "aiEngineModel",
    }),
    relatedPrompts() {
      if (!this.selectedPrompt) {
        return [];
      }

      return this.prompts.filter((record) => record.ai_prompt_id === this.selectedPrompt);
    },
    uniqueFilteredPrompts() {
      if (!this.prompts) {
        return [];
      }

      const uniquePromptsSet = new Set();

      return this.prompts.filter((prompt) => {
        if (!uniquePromptsSet.has(prompt.ai_prompt)) {
          uniquePromptsSet.add(prompt.ai_prompt);
          return true;
        }
        return false;
      });
    },
  },
  watch: {
    uniqueFilteredPrompts: {
      handler(currentValue) {
        if (currentValue.length > 0) {
          this.selectedPrompt = currentValue[0].ai_prompt_id;
          this.selectedPromptName = currentValue[0].ai_prompt;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";
@import "@/scss/icons.scss";

.related-list {
  list-style-type: none;
  padding: 0 0 45% 0;
  margin: 0;
  max-height: 100vh;
  overflow-y: auto;

  .related-list-item {
    margin-bottom: 8px;
    background-color: #e3e3e3;
    padding: 24px;
    border-radius: 0 24px 24px 24px;
    font-size: 16px;

    // :deep(.matching-phrase) {
    //   background-color: yellow !important;
    //   transition: background-color 0.3s ease;
    // }

    .icons {
      padding: 20px;
    }

    .related-list-text {
      align-self: stretch;
      color: var(--grey8);

      /* Body/Body 2/Body 16 Regular */
      font-family: Inter;
      @include body2;

      white-space: break-spaces;
    }
  }
}
</style>
