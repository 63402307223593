<template>
  <view-wrapper>
    <div class="tag-drilldown mt-4">
      <sliding-frame :isOpen="isSlidingFrameOpen" @close="toggleSlidingFrame">
        <responses-view :prompts="filteredPrompts" :routeback="routeback" />
      </sliding-frame>
      <div class="d-flex align-items-center">
        <span class="back-arrow fa-lg" @click="handleBackArrowClick"><i class="fas fa-arrow-left"></i></span>
        <span class="selected-tag"># {{ aiTag?.tag }}</span>
      </div>
      <div class="view-selection mt-4 d-flex justify-content-between">
        <client-dropdown :show-title="true" :isTagDrilldown="true" :shouldShowPeers="true" />
        <date-range />
        <ai-models-dropdown />
      </div>

      <div class="data-section d-flex justify-content-between">
        <div class="chart-section">
          <chart-frequency-by-tag :summariesWithTag="summariesWithTag" />
          <chart-peer-compare :summariesWithTag="summariesWithTag" />
          <chart-engine-model-compare :summariesWithTag="summariesWithTag" />
        </div>
        <div v-if="sortedStatements.length" class="top-statements">
          <span class="mt-3 reputation-title">Example of #{{ aiTag?.tag.toLowerCase() }} tag </span>
          <span>{{ formattedTimeRange }}</span>
          <statements class="mt-4"
                      :sortedStatements="sortedStatements"
                      :aiTag="aiTag" />
        </div>
      </div>
    </div>
  </view-wrapper>
</template>


<script>
import ViewWrapper from "@/views/ViewWrapper.vue";
import Statements from "@/components/entity-analysis/Statements.vue";
import ChartFrequencyByTag from "@/components/entity-analysis/ChartFrequencyByTag.vue";
import ChartPeerCompare from "@/components/entity-analysis/ChartPeerCompare.vue";
import ChartEngineModelCompare from "@/components/entity-analysis/ChartEngineModelCompare.vue";
import DateRange from "@/components/form-elements/DateRange.vue";
import AiModelsDropdown from "@/components/form-elements/AiModelsDropdown.vue";
import ClientDropdown from "@/components/form-elements/ClientDropdown.vue";
import SlidingFrame from "@/components/entity-analysis/SlidingFrame.vue";
import ResponsesView from "@/components/entity-analysis/ResponsesView.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    ViewWrapper,
    Statements,
    ChartFrequencyByTag,
    ChartPeerCompare,
    ChartEngineModelCompare,
    DateRange,
    AiModelsDropdown,
    ClientDropdown,
    SlidingFrame,
    ResponsesView,
  },

  data() {
    return {
      summaries: {
        list: [],
        byDate: {},
      },
      isSlidingFrameOpen: false,
      chartDataForTag: {},
      selectedDate: null,
      summariesWithTag: [],
      filteredPrompts: [],
      prompts: [],
      defaultTimeSelection: [this.$dateTime.now().minus({ days: 6 }).toJSDate(), this.$dateTime.now().toJSDate()],
    };
  },
  props: ["routeback"],
  methods: {
    ...mapActions("ai", [
      "setAiPromptGroup",
    ]),     
    handleBackArrowClick() {
      this.$router.push({ path: "/" + this.routeback });
    },
    toggleSlidingFrame() {
      this.isSlidingFrameOpen = !this.isSlidingFrameOpen;
    },
    fetchsummariesWithTag() {
      return new Promise((resolve, reject) => {
        if (!this.userInfo?.client?.clientid) {
          return false;
        }

        this.$api
          .getSummariesWithTags(
            this.aiPromptGroup?.id,
            this.selectedTimeRange,
            this.aiTag.ai_prompt_template_tag_id
          )
          .then((result) => {
            if (result && result.data) {
              this.summariesWithTag = result.data;
              resolve(result);
            } else {
              this.summariesWithTag = [];
              reject("Did not receive summaries with tag");
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetchPrompts() {
      return new Promise((resolve, reject) => {
        this.$api
          .mongoGetResponsesByPromptGroup(this.aiPromptGroup.id, this.selectedTimeRange)
          .then((result) => {
            if (result && result.data) {
              this.prompts = result.data;
              resolve(result);
            } else {
              reject("Did not receive prompts");
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    formatDate(date) {
      // Format the date to MM/DD/YYYY
      const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
      return formattedDate;
    }
  },
  watch: {
    aiTag() {
      this.fetchsummariesWithTag();
    },
  },
  computed: {
    ...mapGetters("general", {
      view: "view",
    }),
    ...mapGetters("ai", {
      aiTag: "aiTag",
      aiPromptGroup: "aiPromptGroup",
      aiPromptGroups: "aiPromptGroups",
      selectedTimeRange: "selectedTimeRange",
      aiEngineModel: "aiEngineModel",
      userInfo: "userInfo",
    }),
    formattedTimeRange() {

      const startDate = this.selectedTimeRange[0];
      const endDate = this.selectedTimeRange[1];

      if (startDate.toDateString() === endDate.toDateString()) {
        // If both dates are the same
        return `on date of ${this.formatDate(startDate)}`;
      } else {
        // If it's a range
        return `between dates of ${this.formatDate(startDate)} - ${this.formatDate(endDate)}`;
      }
    },
    sortedStatements() {
      if (this.summariesWithTag.length) {
        // Lets get only the prompt group
        const promptGroupSummaries = this.summariesWithTag.filter(
          (summary) => summary.ai_prompt_group_id === this.aiPromptGroup.id
        );
        let summaries = promptGroupSummaries;
        if (this.aiEngineModel.model_type !== "all") {
          // Lets get selected Ai Engine
          summaries = promptGroupSummaries.filter(
            (summary) => summary.model_type === this.aiEngineModel.model_type
          );
        }

        // Sort the array by the 'frequency' attribute in descending order
        const sortedArray = summaries.slice().sort((a, b) => b.frequency - a.frequency);

        // Return only the first 10 records
        return sortedArray.slice(0, 10);
      }
      return [];
    },
  },

  mounted() {
    /*
     ** init ref variables
     */

    // if peer selected, then re-select original prompt group
    if (this.aiPromptGroup?.parent_prompt_group_id) {
      const parent =  this.aiPromptGroups.find(promptGroup => promptGroup.id === this.aiPromptGroup.parent_prompt_group_id);
      this.setAiPromptGroup(parent ? parent : null);
    }

    this.$watch(
      (vm) => [vm.selectedTimeRange, vm.aiPromptGroup, vm.aiEngineModel],
      () => {
        if (
          this.aiPromptGroup?.id &&
          this.selectedTimeRange &&
          this.aiEngineModel?.model_type &&
          this.aiTag?.ai_prompt_template_tag_id
        ) {
          try {
            this.fetchsummariesWithTag();
            this.fetchPrompts();
          } catch (error) {
            console.log(error);
          }
        }
      },
      { immediate: true }
    );

    // probably hit refresh and so we want to go back to entity analysis
    if (!this.aiTag) {
      this.$router.push({ path: "/" + this.view });
    }

  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";

@mixin title-styles {
  color: var(--Primary, #545f71);
  @include body4;
  line-height: 19px;
  letter-spacing: -0.24px;
}

.tag-drilldown {
  padding: 50px;
  max-width: 600px;

  .view-selection {
    width: 750px;
  }

  .back-arrow {
    color: #545f71;
    cursor: pointer;
    padding: 0 5px;
  }

  .tag-section-title {
    @include title-styles;
  }

  .selected-tag {
    @include title-styles;
    font-size: 32px;
    text-transform: lowercase;
  }

  .data-section {
    margin-top: 15px;

    :deep(.chart-container) {
      width: 530px;
      height: 250px;
    }
  }

  .top-statements {
    display: flex;
    width: 546px;
    height: 1251px;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 20px 20px 0px 0px;
    border: 1px solid var(--grey2);
    background: var(--white);
    margin-left: 10px;

    .reputation-title {
      color: var(--grey9);
      @include h2-style;
    }
  }

  .chart-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
}
</style>
