<template>
  <div v-click-outside="closeCalendar">
    <div class="calendar-input" @click="openCalendar" @keyup.enter="closeCalendar">

      <div v-if="!this.range" class="icons arrow arrow-left nav-icon" @click.stop="changeDay(-1)"></div>

      <input type="text" class="start" v-model="displayStartDate">

      <template v-if="this.range">
        <div class="separator">&ndash;</div>
        <input type="text" class="end" v-model="displayEndDate">
      </template>

      <span class="icons header-calendar"></span>

      <div v-if="!this.range" class="icons arrow arrow-right nav-icon"
        :class="{ disabled: isToday(this.$dateTime.fromJSDate(this.selectedTimeRange[0])) }" @click.stop="changeDay(1)">
      </div>

    </div>

    <div class="calendar-popup-container" v-if="isCalendarOpen">

      <div class="calendar">
        <div class="header">
          <div class="back" @click="changeMonth(-1)"><span class="icons arrow arrow-left"></span></div>
          <div class="selected-month">{{ selectedMonthName }} {{ selectedYear }}</div>
          <div class="forward" :class="{ disabled: isCurrentMonth }" @click="changeMonth(1)">
            <span class="icons arrow arrow-right"></span>
          </div>
        </div>
        <div class="dates" :class="{ 'reverse-highlighting': !hoveredDateIsLater() }">
          <div class="weekdays">
            <div v-for=" weekday  in  weekdays " :key="weekday">{{ weekday }}</div>
          </div>
          <div v-for=" week  in  datesByWeek " :key="week" class="week">
            <div v-for=" date  in  week " :key="date" class="date" :class="{
              'clicked-start': isClickedStart(date),
              'hovered-end': isHoveredEnd(date),
              'in-clicked-range': inSelectedRange(date),
              'in-month': date.inMonth,
              today: date.isToday,
              selectable: date.isSelectable,
            }" @click="handleDateSelect(date)" @mouseover="handleDateHover(date)">
              {{ date.dateObject.getDate() }}
            </div>
          </div>
        </div>
      </div>

      <div v-if="range" class="shortcuts-panel">
        <div v-for="shortcut in shortcuts" :key="shortcut" @click="setShortcut(...shortcut.value())" class="shortcut">
          {{ shortcut.text }}
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from "click-outside-vue3";
import { mapActions } from "vuex";

const DISPLAY_FORMAT = 'M/d/yyyy';

export default {
  props: ["range"],
  data() {
    return {
      weekdays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      selectedMonth: this.$dateTime.now(),
      clickedStart: null,
      hoveredDate: null,
      isCalendarOpen: false,
      typedStartDate: null,
      typedEndDate: null,
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
  },
  computed: {
    selectedTimeRange: {
      get() {
        return this.range ? this.$store.state.ai.selectedTimeRange : [this.$store.state.ai.selectedDate, this.$dateTime.now()];
      },
      set(value) {
        this.range ? this.setSelectedTimeRange(value) : this.setSelectedDate(value[0]);
      },
    },
    selectedMonthName() {
      return this.selectedMonth.toFormat("MMMM");
    },
    selectedYear() {
      return this.selectedMonth.toFormat("yyyy");
    },
    datesByWeek() {
      const SUNDAY = 7; // luxon calls Monday the start of the week
      const calendarArray = [];

      let calendar = this.selectedMonth.startOf('month');
      if (calendar.weekday != SUNDAY) {
        calendar = calendar.startOf('week').plus({ days: -1 });
      }
      let week = [];
      let inMonth = (calendar.day == 1);

      for (; calendarArray.length == 0 || week.length || inMonth; inMonth = (calendar.day == 1 ? !inMonth : inMonth)) {
        week.push({
          dateObject: calendar.startOf('day').toJSDate(),
          inMonth,
          isToday: this.isToday(calendar),
          isSelectable: calendar.startOf('day').toSeconds() <= this.$dateTime.now().startOf('day').toSeconds(),
        });

        if (week.length == 7) {
          calendarArray.push(week);
          week = [];
        }

        calendar = calendar.plus({ days: 1 });
      }

      return calendarArray;
    },
    displayStartDate: {
      get() {
        return this.typedStartDate ? this.typedStartDate : this.formatDate(this.selectedTimeRange[0]);
      },
      set(value) {
        this.typedStartDate = value;
      }
    },
    displayEndDate: {
      get() {
        return this.typedEndDate ? this.typedEndDate : this.formatDate(this.selectedTimeRange[1]);
      },
      set(value) {
        this.typedEndDate = value;
      }
    },
    isCurrentMonth() {
      return this.selectedMonth.startOf('month').toSeconds() == this.$dateTime.now().startOf('month').toSeconds();
    },
    shortcuts() {
      return [
        {
          text: 'Today',
          value: () => {
            return [this.$dateTime.now().toJSDate(), this.$dateTime.now().toJSDate()];
          },
        },
        {
          text: 'Last 7 days',
          value: () => {
            const last7Days = this.$dateTime.now().minus({ days: 6 });
            return [last7Days.toJSDate(), this.$dateTime.now().toJSDate()];
          },
        },
        {
          text: 'Last 30 days',
          value: () => {
            const last30Days = this.$dateTime.now().minus({ days: 29 });
            return [last30Days.toJSDate(), this.$dateTime.now().toJSDate()];
          },
        },
        {
          text: 'Last month',
          value: () => {
            const lastMonth = this.$dateTime.now().minus({ months: 1 });
            const startOfMonth = lastMonth.startOf("month");
            const endOfMonth = lastMonth.endOf("month");
            return [startOfMonth.toJSDate(), endOfMonth.toJSDate()];
          },
        },
      ];
    },
  },

  methods: {
    ...mapActions("ai", ["setSelectedTimeRange", "setSelectedDate"]),

    changeMonth(by) {
      if (by > 0 && this.isCurrentMonth) {
        return;
      }

      this.selectedMonth = this.selectedMonth.plus({ months: by });
    },

    isToday(date) {
      return date.startOf('day').toSeconds() == this.$dateTime.now().startOf('day').toSeconds();
    },

    changeDay(by) {
      // for now this is only for the single date picker
      const date = this.$dateTime.fromJSDate(this.selectedTimeRange[0]);

      if (by > 0 && this.isToday(date)) {
        return;
      }

      this.selectedTimeRange = [date.plus({ days: by }).toJSDate(), this.$dateTime.now()];
      this.closeCalendar();
    },

    openCalendar() {
      this.clickedStart = this.hoveredDate = null;
      this.isCalendarOpen = true;
      this.selectedMonth = this.$dateTime.fromJSDate(this.selectedTimeRange[0]);
    },
    closeCalendar() {
      this.isCalendarOpen = false;
      this.updateManuallyTypedDates();
    },
    handleDateSelect(date) {
      if (!date.isSelectable) {
        return;
      }

      if (!this.range) {
        this.clickedStart = date.dateObject;
      }

      if (this.clickedStart) {
        this.selectedTimeRange = this.sortDates(this.clickedStart, date.dateObject);
        this.closeCalendar();
      } else {
        this.clickedStart = date.dateObject;
      }
    },
    handleDateHover(date) {
      if (!date.isSelectable) {
        return;
      }
      if (this.clickedStart) {
        this.hoveredDate = date.dateObject;
      }
    },
    setShortcut(date1, date2) {
      this.selectedTimeRange = [date1, date2];
      this.closeCalendar();
    },
    isClickedStart(date) {
      return this.clickedStart && date.dateObject.getTime() == this.clickedStart.getTime();
    },
    isHoveredEnd(date) {
      return this.hoveredDate && date.dateObject.getTime() == this.hoveredDate.getTime();
    },
    inSelectedRange(date) {
      if (!(this.clickedStart && this.hoveredDate)) {
        return false;
      }

      const dates = this.sortDates(this.clickedStart, this.hoveredDate);
      return this.hoveredDate
        && date.dateObject.getTime() > dates[0].getTime()
        && date.dateObject.getTime() < dates[1].getTime();
    },
    formatDate(date) {
      return this.$dateTime.fromJSDate(date).toFormat(DISPLAY_FORMAT);
    },
    sortDates(date1, date2) {
      return (this.range && (date1.getTime() > date2.getTime()) ? [date2, date1] : [date1, date2]);
    },
    hoveredDateIsLater() {
      if (!(this.clickedStart && this.hoveredDate)) {
        return false;
      }

      return this.clickedStart.getTime() < this.hoveredDate.getTime();
    },
    updateManuallyTypedDates() {
      let date1, date2;

      if (this.typedStartDate) {
        const typed = this.$dateTime.fromFormat(this.typedStartDate, DISPLAY_FORMAT);
        if (typed.isValid) {
          date1 = typed.toJSDate();
        }
      }

      if (this.typedEndDate) {
        const typed = this.$dateTime.fromFormat(this.typedEndDate, DISPLAY_FORMAT);
        if (typed.isValid) {
          date2 = typed.toJSDate();
        }
      }

      if (date1 || date2) {
        date1 ||= this.selectedTimeRange[0];
        date2 ||= this.selectedTimeRange[1];
        this.selectedTimeRange = this.sortDates(date1, date2);
      }

      this.typedStartDate = this.typedEndDate = null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";
@import "@/scss/icons.scss";


.calendar-popup-container {
  z-index: var(--z-popover);
  display: flex;
  border-radius: 12px;
  background: white;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, .3);
  position: absolute;
}

.shortcuts-panel {
  padding: 1rem;
  padding-right: 0;

  .shortcut {
    padding: .7rem;
    padding-right: 3rem;
    color: gray;
    cursor: pointer;

    &:hover {
      background-color: var(--light-gray);
      color: var(--dark-gray);
    }
  }
}

.calendar {
  border-right: solid 1px var(--light-gray);
  padding: 1rem;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem 0rem;

    .selected-month {
      vertical-align: middle;
    }

    .forward,
    .back {
      cursor: pointer;
      border: solid 1px lightgray;
      border-radius: 50%;
      text-align: center;
      padding: .2rem;

      .icons:after {
        border-color: var(--icon-blue);
      }

      &.disabled .icons:after {
        border-color: gray;
        cursor: not-allowed;
      }
    }
  }


  .dates {
    display: table;
    margin: auto;

    .weekdays {
      display: table-row;

      div {
        display: table-cell;
        padding: .3rem;
        text-align: center;
        color: gray;
      }
    }

    .week {
      display: table-row;

      .date {
        display: table-cell;
        padding: .5rem .7rem;
        text-align: center;
        cursor: pointer;
        color: var(--light-black);

        &:not(.in-month) {
          color: gray;
        }

        &.today {
          color: var(--primary-blue5);
        }

        &:not(.selectable) {
          cursor: not-allowed;
          color: gray;
        }

        &.clicked-start {
          background-color: var(--primary-blue5);
          color: white;
          font-weight: bold;
          border-radius: 50%;
          position: relative;

          &:after {
            content: ' ';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--light-gray);
            z-index: -1;
            border-radius: 50% 0 0 50%;
          }
        }

        &.hovered-end {
          background-color: var(--primary-blue5);
          color: white;
          font-weight: bold;
          border-radius: 50%;
          position: relative;

          &:after {
            content: ' ';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--light-gray);
            z-index: -1;
            border-radius: 0 50% 50% 0;
          }
        }

        &.in-clicked-range {
          background-color: var(--light-gray);
        }

      }
    }
  }

  .dates.reverse-highlighting .week .date {
    &.clicked-start:after {
      border-radius: 0 50% 50% 0;
    }

    &.hovered-end:after {
      border-radius: 50% 0 0 50%;
    }
  }
}

.calendar-input {
  border-radius: 30px;
  border: 1px solid var(--grey3);
  background: var(--white);
  padding: .65rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  height: 3rem;
  margin-top: .08rem;

  .start,
  .end {
    // TODO get font styles from somewhere global
    font-size: 14px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;

    border: 0;
    outline: none;
    width: 6rem;
    padding: 0 .5rem;
    text-align: center;
  }

  .nav-icon {
    cursor: pointer;

    &:hover:after {
      border-color: var(--icon-blue);
    }

    &:after {
      border-color: var(--dark-gray);
    }

    &.disabled {
      cursor: default;

      &:after {
        border-color: var(--light-gray);
      }
    }
  }
}
</style>
