<template>
  <div v-if="showProgress" class="spinner-progress">
    <div class="spinner">
      <div class="spinner-border text-primary"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SpinnerProgress",
  props: {},
  data() {
    return {};
  },
  components: {},
  computed: {
    ...mapGetters("general", {
      showProgress: "showProgress",
    }),
  },
};
</script>

<style lang="scss" scoped>
.spinner-progress {
  .spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .spinner-border {
      font-size: 26px;
      width: 5rem;
      height: 5rem;
    }
  }

  margin: auto;
  position: fixed;
  z-index: var(--z-raise);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.192);
}
</style>
