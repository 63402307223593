<template>
  <div class="date-selector d-flex flex-column">
    <span v-if="showLabel" class="title">Date</span>
    <Calendar />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Calendar from "./Calendar.vue";

export default {
  props: {
    showLabel: {
      type: Boolean,
      default: true
    }
  },  
  data() {
    return {
    };
  },
  components: {
    Calendar,
  },
  computed: {
    selectedDate: {
      get() {
        return this.$store.state.ai.selectedDate;
      },
      set(value) {
        this.setSelectedDate(value);
      },
    },
  },
  methods: {
    ...mapActions("ai", ["setSelectedDate"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";

.date-selector {

  // TODO this was lifted from the shared dropdown file which is changing - need to refactor
  .title {
    @include title-styles;
    margin-left: 3px;
    color: var(--Primary, #545f71);
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: -0.24px;
  }
}
</style>




