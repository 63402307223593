<template>
  <div class="tabs">
    <button :class="{ tab: true, active: selectedTab === 'summaries' }" @click="selectTab('summaries')">
      Summaries
    </button>
    <button :class="{ tab: true, active: selectedTab === 'responses' }" @click="selectTab('responses')">
      Responses
    </button>
  </div>
</template>

<script>
export default {
  props: {
    selectedTab: String,
  },
  methods: {
    selectTab(tab) {
      this.$emit('tab-selected', tab);
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  display: flex;

  button {
    cursor: pointer;
    padding: 10px;
    margin: 5px;
    border: none;
    outline: none;

    &.active {
      border-bottom: 3px solid var(--primary-blue5) !important;
    }
  }

  .tab {
    cursor: pointer;
    display: flex;
    width: 142px;
    height: 48px;
    padding: 12px 0px 14px 0px;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid var(--grey3) !important;
    background-color: transparent;

    &.active {
      border-bottom: 2px solid var(--Primary, #545f71);
    }
  }
}
</style>
