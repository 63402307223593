<template>
  <div class="container">&nbsp;
  </div>
</template>

<script>

export default {
  data() {
    return {
    };
  },
  components: {
  },
  computed: {
  },
  methods: {
  },
};

</script>

<style lang="scss" scoped>

.container {
  padding: 3rem 0;
}  

</style>
