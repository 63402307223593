<template>
  <SecurityLayout>
    <template v-slot:content>
      <div class="reset-password-container">
        <div class="logo-container text-center">
          <router-link to="/" class="logo" title="fiveblocks">
            <app-logo theme="dark" size="large" class="app-logo" />
          </router-link>
        </div>

        <div class="sign-in-text">
          <span>Reset password</span>
        </div>

        <div v-if="showAfterSend" id="after-send">
          <div class="text-center dark-blue">
            <h2>Thank you</h2>
          </div>
          <div class="d-flex flex-column">
            <div class="text-center p-5">Your password was reset.</div>
            <router-link id="continue-link" class="text-center" to="/">
              Continue to AIQ
            </router-link>
          </div>
        </div>

        <div v-else id="before-send">
          <div class="text-center dark-blue">
            <h2>Please provide a new password</h2>
          </div>
          <br />
          <div class="form-reset">
            <form>
              <div class="form-group input-field">
                <label>Password</label>
                <input
                       v-model="password"
                       :type="showPassword ? 'text' : 'password'"
                       name="password"
                       id="password"
                       class="form-control"
                       autocomplete="new-password" />
                <div class="icon-container" @click="toggleShowPassword">
                  <span class="icons eye" />
                </div>
                <span v-if="verifyPass" class="is-ok">&check;</span>
              </div>

              <div class="form-group input-field">
                <label class="form-label" for="verify">Repeat your password</label>

                <input
                       v-model="verify"
                       :type="showVerify ? 'text' : 'password'"
                       name="verify"
                       id="verify"
                       class="form-control"
                       autocomplete="new-password" />
                <div class="icon-container" @click="toggleShowVerify">
                  <span class="icons eye" />
                </div>
                <span v-if="verifyVerify" class="is-ok">&check;</span>
              </div>

              <div class="form-item">
                <button class="btn bright-blue" @click.prevent="submitForm" :disabled="isLoading">
                  <span v-if="isLoading"> <i class="fa fa-spinner fa-spin mr-1"></i></span>
                  <span class="button-text">Set new password</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </template>
  </SecurityLayout>
</template>

<script>
import CommonPasswordFile from "!raw-loader!@/assets/txt/common_password.txt";
import SecurityLayout from "@/views/SecurityLayout.vue";
import AppLogo from "@/components/AppLogo";

export default {
  data() {
    return {
      password: "",
      verify: "",
      verifyPass: false,
      verifyVerify: false,
      showAfterSend: false,
      isLoading: false,
      showPassword: false,
      showVerify: false,
    };
  },
  components: {
    SecurityLayout,
    AppLogo,
  },
  methods: {
    async submitForm() {
      const code = this.$route.query.code;

      if (!code) {
        this.$notifier.info({ message: "Code parameter is missing" });
        return;
      }

      // Call the verification functions initially
      this.verifyPass = this.verifyPassFn();
      this.verifyVerify = this.verifyVerifyFn();

      if (!this.verifyPass || !this.verifyVerify) {
        this.$notifier.info({
          message: "Invalid Password: Your password must be at least 8 characters long and include at least one uppercase letter, one number, and one special character (e.g., !, @, #). Please try again",
          duration: 5000
        });
        return;
      }

      try {
        this.isLoading = true;
        const response = await this.$api.resetPassword(code, this.password);

        if (response.data.status === "ok") {
          this.showAfterSend = true;
        } else {
          this.$notifier.info({ message: response.data.status });
        }
      } catch (e) {
        this.$notifier.error({ message: "Failed requesting password reset", e });
      } finally {
        this.isLoading = false;
      }
    },
    verifyPassFn() {
      return (
        this.password.trim() === this.password &&
        this.password.length >= 8 &&
        !this.commonPassword.isCommon(this.password)
      );
    },
    verifyVerifyFn() {
      return this.verifyPassFn() && this.verify === this.password;
    },
    loadCommonPasswords() {
      try {
        // CommonPasswordFile is imported using raw-loader, so it's already a string
        this.commonPassword.data = CommonPasswordFile.split("\n");
      } catch (e) {
        this.$notifier.error({ message: "Error loading common passwords", e });
      }
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    toggleShowVerify() {
      this.showVerify = !this.showVerify;
    },
  },
  watch: {
    password() {
      this.verifyPass = this.verifyPassFn();
    },
    verify() {
      this.verifyVerify = this.verifyVerifyFn();
    },
  },
  mounted() {
    // Load common passwords data
    this.loadCommonPasswords();
  },

  computed: {
    commonPassword() {
      return {
        data: [],
        isCommon: (password) => this.commonPassword.data.includes(password),
      };
    },
  },
};
</script>


<style scoped lang="scss">
@import "@/scss/_icons.scss";
@import "@/scss/_mixins.scss";
@import "@/scss/_ai-saas-common.scss";

.reset-password-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 22.75rem;

  .sign-in-text {
    color: var(--grey9);
    @include h1-style;
    font-size: 1.5rem;
  }

  h2 {
    color: var(--grey9);
    @include body3;
  }

  .header {
    padding: 20px 0;
  }

  .form-item {
    margin-bottom: 15px;

    label {
      display: block;
      font-size: 18px;
      color: #073155;
      margin-bottom: 5px;
    }

    .form-control {
      width: 100%;
    }
  }

  .form-reset {
    width: 300px;
    gap: 1rem;
    display: flex;
    flex-direction: column;

    form {
      gap: 0.75rem;
      display: flex;
      flex-direction: column;

      button {
        font-size: 20px;
        width: 290px;
        line-height: 40px;
        background: #0e3e67;
        height: 40px;
        padding: 0 20px;
        border: 0;
      }

      .input-field {
        position: relative;

        input {
          flex: 1 0 0;
          color: var(--grey7);
          @include body3;
        }

        .is-ok {
          position: absolute;
          font-size: 20px;
          color: green;
          font-weight: bold;
          margin-left: 19.5rem;
          top: 2rem;
        }

        .icon-container {
          position: absolute;
          right: 0.6rem;
          top: 2rem;
        }

        label {
          display: flex;
          width: 22.5rem;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-start;
          gap: 0.25rem;
          margin: 0;
        }
      }
    }
  }

  .logo {
    text-decoration: none;

    img {
      max-width: 100%;
    }
  }

  #after-send {
    .dark-blue {
      margin-top: 10px;
    }

    #continue-link {
      margin: 0 auto;
      padding: 0 50px;
      width: 400px;
      display: block;
      color: #0f6cbc;
      text-decoration: none;
    }
  }

  #before-send {
    .dark-blue {
      margin-top: 10px;
    }

    .form-item {
      margin-bottom: 15px;

      label {
        display: block;
        font-size: 18px;
        color: #073155;
        margin-bottom: 5px;
      }

      .form-text {
        width: 100%;
      }

      .button-dark-blue {
        font-size: 20px;
        width: 290px;
        line-height: 40px;
        background: #0e3e67;
        height: 40px;
        padding: 0 20px;
        border: 0;
      }
    }
  }
}
</style>
