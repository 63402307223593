<!-- ForgotPassword.vue -->
<template>
  <SecurityLayout>
    <template v-slot:content>
      <div class="forgot-password-container">
        <div class="logo-container text-center">
          <router-link to="/" class="logo" title="fiveblocks">
            <app-logo theme="dark" size="large" class="app-logo" />
          </router-link>
        </div>

        <div class="reset-password-text">
          <span>Reset Password</span>
        </div>

        <div class="form-login">
          <div class="form-group input-field email">
            <label>Email</label>
            <input
                   id="email"
                   type="text"
                   name="email"
                   class="form-control"
                   :class="{ 'is-invalid': !isEmailValid }"
                   v-model="email" />
          </div>

          <div class="form-item">
            <button class="btn bright-blue" @click="submitForm" :disabled="isLoading">
              <span v-if="isLoading"> <i class="fa fa-spinner fa-spin mr-1"></i></span>
              <span class="button-text">Send reset password instructions</span>
            </button>
          </div>
        </div>

        <div class="text-center">
          <router-link to="/login" class="login-link">Back to Login</router-link>
        </div>
      </div>
    </template>
  </SecurityLayout>
</template>

<script>
import SecurityLayout from "@/views/SecurityLayout.vue";
import AppLogo from "@/components/AppLogo";

export default {
  data() {
    return {
      email: "",
      isEmailValid: true,
      isLoading: false,
    };
  },
  components: {
    SecurityLayout,
    AppLogo,
  },
  methods: {
    async submitForm() {
      this.validateFields();

      if (this.isEmailValid) {
        try {
          this.isLoading = true;
          const response = await this.$api.sendPasswordResetEmail(this.email);

          if (response.data.status === "ok") {
            this.$notifier.info({ message: "Email sent successfully" });
          } else {
            this.$notifier.error({ message: "Failed sending password recovery email" });
          }
        } catch (e) {
          this.$notifier.error({ message: "Failed requesting password recovery email", e });
        } finally {
          this.isLoading = false;
        }
      }
    },
    validateFields() {
      // Email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.isEmailValid = emailRegex.test(this.email);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/_icons.scss";
@import "@/scss/_mixins.scss";
@import "@/scss/_ai-saas-common.scss";

.forgot-password-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 22.75rem;

  .reset-password-text {
    color: var(--grey9);
    @include h1-style;
    font-size: 1.5rem;
  }

  h1 {
    font-size: 24px;
    font-weight: 800;
    color: #0e3e67;
    text-transform: uppercase;
  }

  .btn {
    font-size: 20px;
    width: 290px;
    line-height: 40px;
    background: #0e3e67;
    height: 40px;
    padding: 0 20px;
    border: 0;
  }

  .logo-container {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .login-link, a.logo {
    color: #0f6cbc;
    text-decoration: none;
  }

  .form-login {
    gap: 1rem;
    display: flex;
    flex-direction: column;
  }

  .form-item {
    margin-bottom: 15px;

    label {
      display: block;
      font-size: 18px;
      color: #073155;
      margin-bottom: 5px;
    }

    .form-control {
      width: 100%;
    }
  }

  .is-invalid {
    border-color: #dc3545;
  }
}
</style>
