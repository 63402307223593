<template>
  <button type="button" class="btn" :class="'btn-' + bsType">
    <slot></slot>
  </button>
</template>

<script>

export default {
  name: "FormButton",
  props: {
    bsType: {
      type: String,
      default: 'primary',
    },
  },
  components: {
  },
  computed: {
  },
  methods: {

  },
}

</script>

<style lang="scss" scoped>
button {
  border-radius: 30px;

  &:not(.btn-sm) {
    padding: 0.5rem 2rem;
    height: 42px;
  }

  &.btn-sm {
    padding-left: 2rem;
    padding-right: 2rem;
    height: 36px;
  }

  &.btn-primary {
    background: var(--primary-blue5);
    border-color: var(--primary-blue5);

    &:hover {
      background-color: var(--primary-blue7);
      border-color: var(--primary-blue7);
    }
  }

  &.btn-secondary {
    background: var(--grey4);
    border-color: var(--grey4);
    color: var(--grey9);

    &:hover {
      background-color: var(--grey6);
      border-color: var(--grey6);
    }
  }
}
</style>
