<template>
  <Dropdown :dropdownName="dropdownName"
            :selectedValue="aiEngineModel.model_type"
            :displayValue="getModelName(aiEngineModel.model_type)"
            :showTitle="true"
            :showIcon="true"
            :dropdownButton="aiModelsDropdownButton"
            :dropdownDisplay="aiModelsDropdownDisplay">
    <template v-slot:dropdown-content>
      <ul class="dropdown-menu ai-models-dropdown">
        <li v-for="option in aiModels"
            :value="option"
            :key="option.ai_engine_model_id"
            @click="setAiEngineModel(option)"
            class="dropdown-item">
          <div class="d-flex align-items-center">
            <span class="icons" :class="option.model_type" />
            <span>{{ getModelName(option.model_type) }}</span>
          </div>
        </li>
      </ul>
    </template>
  </Dropdown>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Dropdown from "@/components/form-elements/Dropdown.vue";
import { AiModelDisplayNames } from "@/constants";

export default {
  components: {
    Dropdown,
  },
  data() {
    return {
      dropdownName: "Ai Models",
      aiModelsDropdownButton: "ai-models-dropdown-button",
      aiModelsDropdownDisplay: "ai-models-dropdown-display",
    };
  },
  computed: {
    ...mapGetters("ai", {
      aiEngineModels: "aiEngineModels",
      aiEngineModel: "aiEngineModel",
    }),
    aiModels() {
      const models = [...this.aiEngineModels];
      const modelArr = Object.keys(AiModelDisplayNames);
      return models.sort((a, b) => modelArr.indexOf(a.model_type) - modelArr.indexOf(b.model_type));
    },
  },
  methods: {
    ...mapActions("ai", ["setAiEngineModel"]),
    getModelName(modelType) {
      return AiModelDisplayNames[modelType];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";
@import "@/scss/icons.scss";

.dropdown-menu {
  &.show {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 1.25rem;
    border: 1px solid var(--grey3, #e9eaed);
    background: var(--white, #fff);
    margin: 0;
    padding: 0;

  }

  .dropdown-item {
    cursor: pointer;

    .icons {
      width: 1.5rem;
    }
  }
}

.dropdown-menu.show li {
  display: flex;
  width: 11.25rem;
  padding: 0.75rem 1rem;
  align-items: center;
  gap: 0.625rem;

  div {
    color: var(--grey7, #6d7685);
    font-family: "Inter";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 1.3125rem */
  }
}

.dropdown-menu.show li:first-child {
  border-radius: 1.25rem 1.25rem 0 0;

  div {
    color: var(--grey9, #17191c);
    font-weight: 500;


    /* 1.3125rem */
  }
}

.dropdown-menu.show li:last-child {
  border-radius: 0 0 1.25rem 1.25rem;
}
</style>
