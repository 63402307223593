<template>
  <div class="date-range d-flex flex-column">
    <span class="title">Date Range</span>

    <calendar :range="true" />

  </div>
</template>

<script>
import Calendar from "./Calendar.vue";

export default {
  data() {
    return {
    };
  },
  components: {
    Calendar,
  },
  computed: {
  },

  methods: {
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";
@import "@/scss/icons.scss";



.date-range {

  // TODO this was lifted from the shared dropdown file which is changing - need to refactor
  .title {
    @include title-styles;
    margin-left: 3px;
    color: var(--Primary, #545f71);
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: -0.24px;
  }

}
</style>
