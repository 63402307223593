import { createStore, createLogger } from 'vuex';
import general from '@/store/modules/general';
import ai from '@/store/modules/ai.js';

const debug = process.env.NODE_ENV !== 'production';

export default createStore({
  modules: {
    general,
    ai,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})