<template>

  <div class="container">
    <div class="row">
      <div class="form-group col">
        <div class="form-field">

          <div class="icon-title">
            <label>Topic Name</label>
          </div>
          <div class="description">The topic of interest<br>(e.g. Best Buy Employer Brand, AT&T Stock Performance, etc.)
          </div>

          <input type="text"
                 class="form-control"
                 v-model="displayNameData" />
        </div>
        <div class="form-field">
          <div class="icon-title">
            <label>Entity</label>
          </div>
          <div class="description">The Company, Brand, or Individual. (e.g. Best Buy, AT&T, etc.)</div>
          <input type="text"
                 class="form-control"
                 v-model="selectedAiPromptGroup" />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { AiModelDisplayNames } from "@/constants";

export default {
  props: [
    "selectedAiPromptGroupProp",
    "displayName",
  ],
  emits: ["handlePromptGroupChange", "handleDisplayNameChange"],

  data() {
    return {
      isOpen: false,
    };
  },
  components: {},
  methods: {
    getModelName(modelType) {
      return AiModelDisplayNames[modelType];
    },
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
  },
  computed: {
    selectedAiPromptGroup: {
      get() {
        return this.selectedAiPromptGroupProp;
      },
      set(value) {
        this.$emit("handlePromptGroupChange", value);
      },
    },
    displayNameData: {
      get() {
        return this.displayName;
      },
      set(value) {
        this.$emit("handleDisplayNameChange", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";
@import "@/scss/icons.scss";
@import "@/scss/dialog-prompt-group-model.scss";


.form-field {
  width: 100%;
}
</style>
