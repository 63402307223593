<template>
  <div class="accordion w-100" id="summaryAccordion">
    <div v-if="sortedStatements">
      <ul class="summary-list">
        <li v-for="(summary, index) in sortedStatements" :key="index" class="accordion-item summary-item-container">

          <h2 class="accordion-header" :id="'heading' + index">
            <span class="icons" :class="summary.model_type" />
            <button class="accordion-button custom-accordion-button" type="button" data-bs-toggle="collapse"
              :data-bs-target="'#collapse' + index" aria-expanded="true" :aria-controls="'collapse' + index">
              #{{ aiTag.tag.toLowerCase() }} as seen in {{ modelType }} in the summary of {{ formatDate(summary.datetime) }}
            </button>
          </h2>
          <div :id="'collapse' + index" class="accordion-collapse collapse" :class="{ show: index === 0 }"
            :aria-labelledby="'heading' + index">
            <div class="accordion-body summary-item" v-html="highlightMatchingPhrase(summary)">

            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import aiUtil from '@/utils/aiUtil';
import { AiModelDisplayNames } from "@/constants";
export default {
  props: ["sortedStatements", "aiTag"],
  data() {
    return {};
  },
  computed: {
    modelType() {
      return AiModelDisplayNames[this.sortedStatements[0].model_type];
    },
  },
  methods: {
    formatDate(dateStr) {
      const date = new Date(dateStr);
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const year = date.getFullYear();
      return `${month}/${day}/${year}`;
    },

    highlightMatchingPhrase(summary) {
      if (!this.aiTag?.ai_prompt_template_tag_id) {
        return summary.bullet;
      }

      const tagIdToHighlight = this.aiTag.ai_prompt_template_tag_id;

      // Find the matching tag in the tags array
      const matchingTag = summary.tags.find(
        (tag) => +tag.ai_prompt_template_tag_id === +tagIdToHighlight
      );

      if (matchingTag && matchingTag.phrase) {
        let highlightedContent = aiUtil.stylizeTextBasedFormatting(matchingTag.phrase);

        // If a match is found, highlight the phrase within the matching tag in the content
        const regex = new RegExp(`${highlightedContent}`, "gi");
        return summary.bullet.replace(
          regex,
          (match) => `<span class="matching-phrase">${match}</span>`
        );
      }

      return summary.bullet;
    },    
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";
@import "@/scss/icons.scss";

.summary-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  padding: 0;
  width: 100%;
  list-style: none;

  .summary-item-container {
    width: 100%;
    position: relative;

    .icons {
      position: absolute;
      left: -1.50rem;
      top: 1rem;
    }

    .summary-item {
      color: var(--grey9);
      @include body1;

      :deep(.matching-phrase) {
        background-color: yellow !important;
        transition: background-color 0.3s ease;
      }
    }

    /* Custom styles for the accordion button */
    .custom-accordion-button {
      background-color: white;
      color: black;
      border: 1px solid #dee2e6;
      width: 100%;
      text-align: left;
    }

    .custom-accordion-button:not(.collapsed) {
      background-color: white;
      color: black;
    }

    .custom-accordion-button:focus {
      box-shadow: none;
      border-color: #dee2e6;
    }

    .accordion-collapse {
      width: 100%;
    }
  }
}
</style>
