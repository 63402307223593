<template>
  <div class="peers" :class="{ 'edit-mode': editMode }">

    <div class="d-flex flex-column" v-if="!editMode">
      <div class="icon-title">
        <span class="icons prompt-group-peers" />
        <label>Peers</label>
      </div>
      <div class="description">Compare what AI thinks about peers or competitors.</div>
    </div>

    <div class="add-peer">
      <div class="form-field">
        <label>Add a peer</label>
        <input
               type="text"
               class="form-control"
               v-model="aiPeer"
               :disabled="!canAddPeers"
               @keydown.enter="addAiPeers()" />
      </div>

      <form-button class="btn-sm" :class="{ disabled: !aiPeer.trim() || !canAddPeers }"
                   @click="addAiPeers()">
        Add
      </form-button>
    </div>
    <span class="description" v-if="!canAddPeers">Up to {{ maxPeers }} peers may be included at a time. To add peers,
      first remove one or more from the list.</span>

    <div>
      <div v-for="(aiPeer, index) in aiPeers" :key="index" class="ai-peer">
        <label :for="'peer-' + index">{{ aiPeer.ai_prompt_group }}</label>
        <div class="control-buttons">
          <div @click="removeAiPeer(index)" class="icon-container">
            <span class="icons trash" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormButton from "@/components/form-elements/FormButton.vue";
import { maxPeers } from "@/constants";

export default {
  emits: ["setAiPeer", "addAiPeers", "removeAiPeer"],
  props: {
    aiPeerProp: String,
    aiPeers: Object,
    editMode: Boolean,
  },
  data() {
    return {
      maxPeers,
    };
  },
  components: {
    FormButton,
  },
  computed: {
    aiPeer: {
      get() {
        return this.aiPeerProp;
      },
      set(value) {
        this.$emit("setAiPeer", value);
      },
    },
    canAddPeers() {
      return this.aiPeers.length < maxPeers;
    },
  },
  methods: {
    addAiPeers() {
      this.$emit("addAiPeers");
    },
    removeAiPeer(index) {
      this.$emit("removeAiPeer", index);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";
@import "@/scss/dialog-prompt-group-model.scss";

.peers {
  &:not(.edit-mode) {
    margin-top: 2rem;
    border: solid 1px var(--grey4);
    border-radius: 1.5rem;
    padding: 1rem;
  }
}

.add-peer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: .5rem;
  margin-top: 1rem;

  .form-field {
    flex-grow: 1;
  }

}

.ai-peer {
  display: flex;
  padding: 0.5rem;
  align-items: center;
  gap: 2rem;
  align-self: stretch;

  .icons.trash {
    cursor: pointer;
    transition: all .3s ease;

    &:not(:hover) {
      filter: var(--icon-fade-filter);
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--grey3);
  }

  &:first-child {
    margin-top: .5rem;
  }

  label {
    flex: 1 0 0;
    color: var(--grey9);
    @include h5-style;
  }
}

.edit-mode {
  .form-field>label {
    @include body4;
    color: var(--grey7);
  }
}

.apply-button {
  border-radius: 30px;
  font-family: Inter;
  font-weight: 500;
  background: var(--primary-blue5);
  color: white;
}
</style>
