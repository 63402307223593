import storage from "@/utils/storageUtil";
import api from "@/api";
const { DateTime } = require("luxon");

let waitingToSetActiveModel = false;
let previousModel = "";

const state = () => ({
  initialized: false,
  aiEngineModels: [],
  aiPromptTemplates: [],
  terms: [],
  activeModels: [],
  aiMode: "summary",
  markupVisible: true,
  aiPromptGroup: null,
  aiPromptGroups: [],
  aiPeers: [],
  selectedAiPeers: [],
  aiTag: "",
  aiEngineModel: "",
  aiModel: "all",
  aiResultset: "models",
  peerHistory: false,
  selectedTimeRange: getDefaultTimeRange(),
  selectedDate: new Date(),
  selectedPrompt: null,
  selectedTab: "summaries",
  showSentiment: true,
  showAlsoNoted: true,
  userInfo: {},
  neutralThreshold: 5,
  openResponses: [0],
});

const getDefaultTimeRange = () => {
  const now = DateTime.now();
  return [now.minus({ days: 6 }).toJSDate(), now.toJSDate()];
};

const getters = {
  aiEngineModels: (state) => state.aiEngineModels,
  aiPromptTemplates: (state) => state.aiPromptTemplates,
  terms: (state) => state.terms,
  aiMode: (state) => state.aiMode,
  markupVisible: (state) => state.markupVisible,
  aiPromptGroup: (state) => state.aiPromptGroup,
  aiPromptGroups: (state) => state.aiPromptGroups,
  selectedAiPeers: (state) => state.selectedAiPeers,
  aiTag: (state) => state.aiTag,
  aiEngineModel: (state) => state.aiEngineModel,
  activeModels: (state) => state.activeModels,
  aiModel: (state) => state.aiModel,
  aiResultset: (state) => state.aiResultset,
  isAiViewHistory: (state) => state.aiResultset === "history",
  peerHistory: (state) => state.peerHistory,
  selectedTimeRange: (state) => state.selectedTimeRange,
  selectedDate: (state) => state.selectedDate,
  selectedPrompt: (state) => state.selectedPrompt,
  selectedTab: (state) => state.selectedTab,
  showSentiment: (state) => state.showSentiment,
  showAlsoNoted: (state) => state.showAlsoNoted,
  timeRangeOptions: (state) => state.timeRangeOptions,
  userInfo: state => state.userInfo,
  neutralThreshold: state => state.neutralThreshold,
  openResponses: state => state.openResponses,
};

const actions = {

  loadUserInfo({ commit }) {
    return api.getUserInfo()
      .then((results) => {
        commit('SET_USER_INFO', results.data);
        return results.data;
      })
  },

  fetchPromptGroups({ commit, state }) {
    return new Promise((resolve, reject) => {
      api.getPromptGroupsForClient()
        .then((result) => {
          if (result && result.data) {
            commit('SET_AI_PROMPT_GROUPS', result.data);
            if (!state.aiPromptGroup) {
              commit("SET_AI_PROMPT_GROUP", result.data[0]);
            }

            resolve(result);
          } else {
            reject("Did not receive data");
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  initializeAI(o) {
    const { commit, state } = o;

    if (state.initialized) {
      return;
    }

    api.getAiEngineModels().then((res) => {
      if (res?.data.length > 0) {
        const engineModels = res.data;
        commit('SET_AI_ENGINE_MODELS', engineModels);
        commit('SET_AI_ENGINE_MODEL', engineModels[0]);
      }
    });
    api.getAiPromptTemplates().then((res) => {
      if (res?.data.length > 0) {
        commit('SET_AI_PROMPT_TEMPLATES', res.data);
      }
    });

    return actions.loadUserInfo(o)
      .then(() => {
        commit('SET_INITIALIZED', true);
        return actions.fetchPromptGroups(o);
      });
  },

  setAppropriatePromptGroup({ commit, state }) {
    if (!state.aiPromptGroups.length) {
      return;
    }

    if (!state.aiPromptGroup) {
      commit('SET_AI_PROMPT_GROUP', state.aiPromptGroups[0]);
    }
  },

  setNeutralThreshold({ commit }, payload) {
    commit('SET_NEUTRAL_THRESHOLD', payload);
  },
  setUserInfo({ commit }, userInfo) {
    commit('SET_USER_INFO', userInfo);
  },
  setTerms({ commit }, terms) {
    commit("SET_TERMS", terms);
  },
  setAiMode({ commit }, aiMode) {
    commit("SET_AI_MODE", aiMode);
  },
  setMarkupVisible({ commit }, markupVisible) {
    commit("SET_MARKUP_VISIBLE", markupVisible);
  },
  setAiPromptGroup({ commit }, payload) {
    commit("SET_AI_PROMPT_GROUP", payload);
    commit("SET_SELECTED_PROMPT", null);
  },
  setSelectedAiPeers({ commit }, payload) {
    commit("SET_SELECTED_AI_PEERS", payload);
  },
  setAiEngineModels({ commit }, payload) {
    commit("SET_AI_ENGINE_MODELS", payload);
  },
  setAiTag({ commit }, payload) {
    commit("SET_AI_TAG", payload);
  },
  setAiEngineModel({ commit }, payload) {
    commit("SET_AI_ENGINE_MODEL", payload);
  },
  setActiveModels({ commit }, activeModels) {
    commit("SET_ACTIVE_MODELS", activeModels);
    if (waitingToSetActiveModel) {
      commit("SET_AI_MODEL", activeModels[0]);
      waitingToSetActiveModel = false;
    }
  },
  setAiModel({ commit, state }, payload) {
    commit("SET_AI_MODEL", payload);
    if (payload === "all") {
      commit("SET_AI_RESULTSET", "models");
    } else {
      previousModel = payload;
      if (state.aiResultset === "models") {
        commit("SET_AI_RESULTSET", "history");
      }
    }
  },
  setAiResultset({ commit, state }, payload) {
    commit("SET_AI_RESULTSET", payload);
    if (payload === "models") {
      commit("SET_AI_MODEL", "all");
    } else if (state.aiModel === "all") {
      const newModel = previousModel || state.activeModels[0];
      waitingToSetActiveModel = !newModel;
      if (newModel) {
        commit("SET_AI_MODEL", newModel);
      }
    }
  },
  setPeerHistory({ commit }, peerHistory) {
    commit("SET_PEER_HISTORY", peerHistory);
  },
  setSelectedTimeRange({ commit }, selectedTimeRange) {
    commit("SET_SELECTED_TIMERANGE", selectedTimeRange);
  },
  setSelectedDate({ commit }, selectedDate) {
    commit("SET_SELECTED_DATE", selectedDate);
  },
  setSelectedPrompt({ commit }, selectedPrompt) {
    commit("SET_SELECTED_PROMPT", selectedPrompt);
  },
  setSelectedTab({ commit }, selectedTab) {
    commit("SET_SELECTED_TAB", selectedTab);
  },
  setShowSentiment({ commit }, showSentiment) {
    commit("SET_SHOW_SENTIMENT", showSentiment);
  },
  setAlsoNoted({ commit }, showAlsoNoted) {
    commit("SET_SHOW_ALSO_NOTED", showAlsoNoted);
  },
  toggleResponseOpen({ commit }, responseIndex) {
    commit("TOGGLE_RESPONSE_OPEN", responseIndex);
  },
  setOpenResponses({ commit }, openResponses) {
    commit("SET_OPEN_RESPONSES", openResponses);
  },
};

const mutations = {
  SET_NEUTRAL_THRESHOLD(state, payload) {
    state.neutralThreshold = payload;
  },
  SET_AI_ENGINE_MODELS(state, payload) {
    state.aiEngineModels = payload;
  },
  SET_AI_PROMPT_TEMPLATES(state, payload) {
    state.aiPromptTemplates = payload;
  },
  SET_INITIALIZED(state, payload) {
    state.initialized = payload;
  },
  SET_TERMS(state, payload) {
    state.terms = payload;
  },
  SET_AI_MODE(state, payload) {
    state.aiMode = payload;
    storage.set("aiMode", payload);
  },
  SET_MARKUP_VISIBLE(state, payload) {
    state.markupVisible = payload;
  },
  SET_AI_PROMPT_GROUP(state, payload) {
    state.aiPromptGroup = payload;
  },
  SET_AI_PROMPT_GROUPS(state, payload) {
    state.aiPromptGroups = payload;
  },
  SET_SELECTED_AI_PEERS(state, payload) {
    state.selectedAiPeers = payload;
  },
  SET_AI_TAG(state, payload) {
    state.aiTag = payload;
  },
  SET_AI_ENGINE_MODEL(state, payload) {
    state.aiEngineModel = payload;
  },
  SET_AI_RESULTSET(state, payload) {
    state.aiResultset = payload;
  },
  SET_ACTIVE_MODELS(state, payload) {
    state.activeModels = payload;
  },
  SET_AI_MODEL(state, payload) {
    state.aiModel = payload;
  },
  SET_PEER_HISTORY(state, payload) {
    state.peerHistory = payload;
  },
  SET_SELECTED_TIMERANGE(state, payload) {
    state.selectedTimeRange = payload;
  },
  SET_SELECTED_DATE(state, payload) {
    state.selectedDate = payload;
  },
  SET_SELECTED_PROMPT(state, payload) {
    state.selectedPrompt = payload;
  },
  SET_SELECTED_TAB(state, payload) {
    state.selectedTab = payload;
  },
  SET_SHOW_SENTIMENT(state, payload) {
    state.showSentiment = payload;
  },
  SET_SHOW_ALSO_NOTED(state, payload) {
    state.showAlsoNoted = payload;
  },
  SET_USER_INFO(state, payload) {
    state.userInfo = payload;
  },
  TOGGLE_RESPONSE_OPEN(state, responseIndex) {
    const placeInArray = state.openResponses.indexOf(responseIndex);
    if (placeInArray === -1) {
      state.openResponses.push(responseIndex);
    } else {
      state.openResponses.splice(placeInArray, 1);
    }
  },
  SET_OPEN_RESPONSES(state, openResponses) {
    state.openResponses = openResponses;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
