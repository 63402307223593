<template>
  <div class="d-flex">
    <side-menu />

    <div class="page-wrapper timeline-scrolling-container">
      <div class="body">
        <slot />
      </div>

      <page-footer />
    </div>
    <spinner-progress />

  </div>

</template>

<script>
import { mapGetters, mapActions } from "vuex";

import SpinnerProgress from "@/components/SpinnerProgress.vue";
import SideMenu from "@/components/SideMenu.vue";
import { sidebarItems } from "@/constants";
import PageFooter from "@/components/page-elements/PageFooter.vue";

export default {
  components: {
    SpinnerProgress,
    SideMenu,
    PageFooter,
  },
  data() {
    return {
      sidebarItems,
      showMiniTop: false,
      bodyY: null,
    };
  },
  computed: {
    ...mapGetters("general", {
      view: "view",
    }),
  },
  methods: {
    ...mapActions("general", ["setShowProgress"]),
  },
}
</script>

<style lang="scss">
@import "@/scss/_mixins.scss";

.page-wrapper {
  background: var(--grey1);
  height: 100vh;
  overflow: auto;
  width: 100%;

  .body {
    margin-left: var(--gutter-width);
    margin-top: var(--component-spacing-v);
    margin-right: 1rem;
    margin-bottom: 1rem;
    width: max-content;
  }
}
</style>

<style lang="scss">
.v-toast__item {
  height: 3em;
  min-height: 3em;
}
</style>