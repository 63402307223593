<template>
  <div
       class="modal fade"
       id="deleteModal"
       data-bs-backdrop="static"
       data-bs-keyboard="false"
       tabindex="-1"
       aria-labelledby="deleteModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"></button>
          <p class="me-5">Are you sure you want to delete {{ showFor }}?</p>
          <div class="button-container mt-3">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary ms-2" data-bs-dismiss="modal" @click="confirm()">
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";

export default {
  name: "deleteModal",
  emits: ['confirm', 'setShowFor'],
  components: {},
  props: {
    showFor: String,
  },
  computed: {},
  data() {
    return {
      modal: ""
    };
  },
  methods: {
    confirm() {
      this.$emit('confirm')
    },
    showModal(isShow) {
      this.modal = new Modal(document.getElementById("deleteModal"));
      if (isShow) this.modal.show();
      else this.modal.hide();
    },
  },
  watch: {
    showFor(currentValue) {
      this.showModal(!!currentValue);
    },
  },
  mounted() {
    setTimeout(() => {
      this.modal = document.getElementById("deleteModal");
      if (this.modal)
      this.modal.addEventListener("hidden.bs.modal", () => {
        this.$emit("setShowFor", "");
      });
    }, 1000);
  },
};
</script>

<style lang="scss" scoped>
.modal-dialog {
  .modal-content {
    width: 500px;

    .button-container {
      display: flex;
    }

    .btn-close {
      position: absolute;
      right: 10px;
    }
  }

}
</style>
