<template>
  <div>

    <div class="entity form-field mb-4">
      <label>Topic Name</label>
      <input type="text"
             class="form-control mt-1"
             v-model="displayNameData" />
    </div>

  </div>
</template>

<script>

export default {
  props: [
    "displayName",
    "aiPromptTemplateGroupSelection",
  ],

  data() {
    return {
      isOpen: false,
    };
  },
  components: {},
  methods: {
  },
  computed: {
    displayNameData: {
      get() {
        return this.displayName;
      },
      set(value) {
        this.$emit("handleDisplayNameChange", value);
      },
    },
  },
  watch: {},
  mounted() { },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";
@import "@/scss/icons.scss";
@import "@/scss/dialog-prompt-group-model.scss";

label {
  @include body4;
  color: var(--grey7);
}
</style>
