<template>
  <div class="top-container">
    <div class="sources-title" @click.stop="toggleShowAll()">
      <span>Sources Provided</span>
      <i v-if="sourcesByDomain.length > 1"
         class="open-all-handle"
         :class="'bi bi-chevron-double-' + (isAllOpen ? 'up' : 'down')" />
    </div>
    <div class="d-flex flex-column">
      <div class="sources-provided">
        <ol class="domain-list" v-if="sourcesByDomain.length > 0">
          <li v-for="(domain, dIndex) in sourcesByDomain" :key="dIndex"
              @click.stop="toggleOpen(dIndex)"
              :class="{ 'is-source-open': isOpen(dIndex) }">
            <div class="favicon-container">
              <img class="favicon bi bi-globe-americas"
                   :src="aiUtil.getFaviconUrl(domain.display)"
                   onerror="this.classList.add('generic'); this.src=''" 
                   onload="this.classList.remove('generic')" />
            </div>
            <a>{{ domain.display }}</a>
            <span class="domain-count badge rounded-pill text-bg-primary"
                  v-if="domain.totalCount > 1">
              {{ domain.totalCount }}
            </span>

            <div class="open-domain-handle icons arrow"
                 :class="'arrow-' + (isOpen(dIndex) ? 'up' : 'down')"></div>


            <ul class="url-list">
              <li v-for="(source, sIndex) in domain.sources" :key="sIndex">
                <a :class="{ 'has-count-indicator': source.count > 1 }" :href="source.url" target="_blank">
                  {{ source.display }}
                </a>
                <span class="source-count badge rounded-pill text-bg-light" v-if="source.count > 1">
                  {{ source.count }}
                </span>
              </li>
            </ul>
          </li>
        </ol>
        <p v-else>None</p>
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters } from "vuex";
import aiUtil from "@/utils/aiUtil.js";

export default {
  props: {
    prompts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      aiUtil,
      sourcesByDomain: [],
      openSources: [],
    };
  },
  computed: {
    ...mapGetters("ai", {
      aiEngineModel: "aiEngineModel",
    }),

    isAllOpen() {
      return this.sourcesByDomain.length === this.openSources.length;
    },
  },
  methods: {
    toggleOpen(sourceIndex) {
      const placeInArray = this.openSources.indexOf(sourceIndex);
      if (placeInArray === -1) {
        this.openSources.push(sourceIndex);
      } else {
        this.openSources.splice(placeInArray, 1);
      }
    },

    isOpen(sourceIndex) {
      return this.openSources.includes(sourceIndex);
    },

    selectAiPromptGroup(promptGroup) {
      this.selectedPromptGroup = promptGroup;
    },

    toggleShowAll() {
      this.openSources = this.isAllOpen ? [] : [...Array(this.sourcesByDomain.length).keys()];
    },

    sanitizeURL(url) {
      return url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '');
    },

    fetchSources() {
      if (!this.prompts) {
        return;
      }

      const notNull = (item) => item;
      // Extract references arrays from prompts and flatten them into a single list and remove null values
      const references = this.prompts.flatMap((prompt) => prompt.references).filter(notNull);

      // Count occurrences of each reference
      const referenceCounts = {};
      references.forEach((reference) => referenceCounts[reference.url] = (referenceCounts[reference.url] || 0) + 1);

      // Convert referenceCounts object to an array of objects with url, count properties, and sort descending
      const sources = Object.keys(referenceCounts).map((url) => ({
        url: url,
        display: this.sanitizeURL(url),
        count: referenceCounts[url],
      })).sort((a, b) => b.count - a.count);

      // group by domain

      const byDomainMap = {};
      sources.forEach((source) => {
        const domain = aiUtil.extractDomain(source.url);
        byDomainMap[domain] ||= [];
        byDomainMap[domain].push(source);
      });

      this.sourcesByDomain = Object.keys(byDomainMap).map((domain) => ({
        display: domain,
        sources: byDomainMap[domain],
        totalCount: byDomainMap[domain].reduce((partialSum, source) => partialSum + source.count, 0),
      })).sort((a, b) => b.totalCount - a.totalCount);
    },
  },
  mounted() {
    this.fetchSources();
  },
  watch: {
    prompts: {
      handler: "fetchSources",
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";
@import "@/scss/icons.scss";

.top-container {
  max-width: 25rem;
}

.sources-title {
  color: var(--grey7);
  margin-bottom: 1rem;
  @include h4-style;
  display: flex;
  justify-content: space-between;
  margin-right: .1rem;
  cursor: default;

  &:hover .open-all-handle {
    color: var(--icon-blue);
  }

  .open-all-handle {
    color: var(--grey4);

  }
}

.sources-provided {
  font-size: 14px;

  .domain-list {
    max-width: 100%;
    list-style-type: none;
    padding-left: 0;

    .domain-count {
      position: absolute;
      right: 1.8rem;
      cursor: default;
    }

    &>li {
      position: relative;
      padding: 0.2rem 0;
      color: var(--grey8);
      cursor: default;

      &>* {
        vertical-align: middle;
      }

      &:not(:last-of-type) {
        border-bottom: solid 1px var(--grey3);
      }

      &:hover .open-domain-handle:after {
        border-color: var(--icon-blue);
      }

      a {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .open-domain-handle {
        position: absolute;
        right: -.7rem;
        top: 10px;

        &:after {
          border-color: var(--grey4);
        }
      }

      .badge {
        font-family: Arial, Helvetica, sans-serif;

        &.text-bg-primary {
          background-color: var(--primary-blue5) !important;
        }

        &.text-bg-light {
          background-color: var(--grey3) !important;
        }
      }

      &:not(.is-source-open) .url-list {
        transition: none;
        height: 0;
        opacity: 0;
        padding: 0;

        li {
          height: 0;
          overflow: hidden;
        }
      }
    }

    .favicon-container {
      margin-right: .3rem;
      width: 18px;
      height: 18px;
      display: inline-block;
      margin-left: 3px;

    }

    .favicon {
      max-width: 100%;
      max-height: 100%;
      vertical-align: unset;

      &.generic {
        color: var(--grey6);
        margin-left: 2px;
      }

      &:not(.generic) {
        border-radius: 40px;
        box-shadow: 0px 0px 3px var(--grey7);
        overflow: hidden;
      }
    }
  }

  .url-list {
    list-style-type: none;
    transition: height .3s ease, opacity .3s ease;
    padding-left: 26px;
    padding-top: .2rem;

    li {
      &>* {
        vertical-align: middle;
      }

      a {
        display: inline-block;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: .1rem 0;

        &.has-count-indicator {
          max-width: calc(100% - (.5rem + 25px)); // space before pill, max size of pill

          &~.source-count {
            position: relative;
            left: .5rem;
            cursor: default;
          }

        }
      }
    }
  }

  .title {
    color: var(--grey9);
    @include h4-style;
  }

}
</style>
