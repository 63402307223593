<template>
  <div>
    <div class="overlay" v-if="isOpen" @click="close"></div>
    <div class="sliding-frame" :class="{ open: isOpen }">
      <div class="sliding-frame-content">
        <button class="close-button" @click="close">
          <span class="icons close" />
        </button>
        <span class="spacer" />
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: lightgray;
  z-index: 999;
  opacity: 0.4;
}

.sliding-frame {
  position: fixed;
  top: 0;
  right: -600px;
  height: 100%;
  width: 50%;
  max-width: 600px;
  background-color: white;
  box-shadow: -4px 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: right 0.3s ease;
  z-index: 1000;

  border-radius: 20px 0px 0px 20px;
  background: var(--White, #fff);

  &.open {
    right: 0;
  }

  .sliding-frame-content {
    padding: 20px;
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    border: 0;
    display: inline-flex;
    padding: 10px;
    align-items: flex-start;
    gap: 10px;

    border-radius: 30px;
    background: var(--Blue1, #f4f6fa);
  }
  .spacer {
    margin-top: 30px;
    display: block;
  }
}
</style>
