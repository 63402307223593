<template>
  <Dropdown :dropdownName="dropdownName" :selectedValue="selectedPrompt" :dropdownButton="promptDropdownButton"
        :dropdownDisplay="promptDropdownDisplay" :showTitle="showTitle">
    <template v-slot:dropdown-content>
      <ul class="dropdown-menu prompt-menu">
        <li v-for="prompt in prompts" :value="prompt" :key="prompt" @click="selectPrompt(prompt)"
          class="dropdown-item">
          <div>
            {{ prompt }}
          </div>
          <div class="icons dropdown-arrow-up"></div>
        </li>
      </ul>
    </template>
  </Dropdown>
</template>
 
<script>
import { mapGetters, mapActions } from "vuex";
import Dropdown from "@/components/form-elements/Dropdown.vue";

export default {
  props: {
    showTitle: Boolean,
    uniqueAiPrompts: Array,
  },


  data() {
    return {
      dropdownName: 'Prompt',
      promptDropdownButton: "prompt-dropdown-button",
      promptDropdownDisplay: "prompt-dropdown-display",
    };
  },
  components: {
    Dropdown
  },
  methods: {
    ...mapActions("ai", [
      "setSelectedPrompt",
    ]),
    selectPrompt(prompt) {
      this.setSelectedPrompt(prompt);
    }
  },
  computed: {
    ...mapGetters("ai", {
      aiPromptGroups: "aiPromptGroups",
      aiPromptGroup: "aiPromptGroup",
      selectedPrompt: "selectedPrompt",
    }),

    selectedAiPromptGroup: {
      get() {
        return this.$store.state.ai.aiPromptGroup;
      },
      set(value) {
        this.setAiPromptGroup(value);
      },
    },
    prompts() {
      if (Array.isArray(this.uniqueAiPrompts)) {
        return this.uniqueAiPrompts.map((prompt) => {
          if (typeof prompt === "object" && prompt !== null && "ai_prompt" in prompt) {
            return prompt.ai_prompt;
          } else {
            return prompt;
          }
        });
      } else {
        return [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";
@import "@/scss/icons.scss";






.dropdown-menu.show {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-bottom: 0;
  padding-bottom: 0.5rem;
  min-width: 33.5rem;
  width: 100%;
  align-items: flex-start;
  border-radius: 1.25rem;
  border: 1px solid var(--grey3, #E9EAED);
  background: var(--white, #FFF);
  cursor: pointer;

}


.dropdown-menu.show li {
  display: flex;
  padding: 0.75rem 1.5rem;
  align-items: flex-start;
  gap: 0.625rem;
  align-self: stretch;
  white-space: wrap;

  div {
    display: flex;
    width: 100%;
    height: auto;
    color: var(--grey7, #6D7685);
    font-family: 'Inter';
    font-size: .875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    /* 1.5rem */

  }

  .icons.dropdown-arrow-up {
    display: none;
  }
}

.dropdown-menu.show li:first-child {
  padding: 1.25rem 1.5rem;
  align-items: center;
  gap: 1rem;
  align-self: stretch;
  border-radius: 1.25rem 1.25rem 0 0;

}

.dropdown-menu.show li:last-child {
  border-radius: 0 0 1.25rem 1.25rem;
}
</style>