<template>
    <Dropdown :selectedValue="aiPromptGroup?.display_name" :dropdownButton="clientDropdownButton"
              :dropdownDisplay="clientDropdownDisplay" :showTitle="showTitle" :dropdownName="dropdownName"
              :isTagDrilldown="isTagDrilldown">
        <template v-slot:dropdown-content>
            <ul class="dropdown-menu">
                <li v-for="(option, index) in aiPromptGroups" :key="option.id" class="dropdown-item"
                    :class="{ 'peer-indent': option.isPeer }" @click="setAiPromptGroup(option)">
                    <div>
                        <div class="myrow">
                            <div class="prompt-group-name">{{ option.display_name}}</div>
                            <div v-if="option.peers" class="icons  collapse-peers "
                                :class="{ 'arrow arrow-down': shouldShowPeers && showPeers(index), 'arrow arrow-right': shouldShowPeers && !showPeers(index) }"
                                @click.stop="toggleShowPeers(index)"> </div>
                        </div>
                    </div>
                    <div>
                        <ul v-if="showPeers(index)">
                            <li v-for="(peer, i) in option.peers" :key="i" @click.stop="setPeerAsPromptGroup( peer)">
                                {{ peer.ai_prompt_group }}
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </template>
    </Dropdown>
</template>

<script>
import Dropdown from "@/components/form-elements/Dropdown.vue";
import { mapGetters, mapActions } from "vuex";

export default {
    props: ["showTitle", "isTagDrilldown", "shouldShowPeers"],

    components: {
        Dropdown,
    },
    data() {
        return {
            dropdownName: "Entity",
            clientDropdownButton: "client-dropdown-button",
            clientDropdownDisplay: "client-dropdown-display",
            showPeersArr: [],
        }
    },
    methods: {
        ...mapActions("ai", [
            "setAiPromptGroup",
        ]),
        setPeerAsPromptGroup(peer) {
            let promptGroup = { id: peer.ai_peer_prompt_group_id, name: peer.ai_prompt_group, display_name: peer.ai_prompt_group, parent_prompt_group_id: peer.ai_parent_prompt_group_id };
            this.setAiPromptGroup(promptGroup);
            this.closeDropDown();
        },
        toggleShowPeers(i) {
            if ( !this.showPeersArr[i]) {
                this.showPeersArr[i] = true;
            }
            else {
                this.showPeersArr[i] = false;
            }
        },
        showPeers(i) {
            if (this.showPeersArr[i] == undefined) {
                return false;
            }
            return this.showPeersArr[i];
        },
        closeDropDown() {
            let elements = document.getElementsByClassName("dropdown-menu show");
            elements[0]?.classList.remove("show");
        }
    },
    computed: {
        ...mapGetters("ai", {
            aiPromptGroup: "aiPromptGroup",
            aiPromptGroups: "aiPromptGroups",
        }),
        promptGroupOptions() {
            return this.aiPromptGroups.flatMap(this.promptGroupToOptions);
        },
    },
}
</script>



<style lang="scss" scoped>
.dropdown-menu.show {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
    border-radius: 0.75rem;
    border: 1px solid var(--grey3, #E9EAED);
    background: var(--white, #FFF);
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.dropdown-menu.show li {
    display: flex;
    flex-direction: column;
    padding: 0.75rem 1rem;
    align-items: left;
    gap: 0.625rem;
    align-self: stretch;
    min-width: auto;

    div {
        color: var(--grey7, #6D7685);
        font-family: 'Inter';
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        /* 1.3125rem */

        .collapse-peers {
            cursor: pointer;
            &.arrow {
                margin-left: 1rem;
            }
            &.arrow-down {
                margin-top: 1rem;
            }
    
            &:hover:after {
                border-color: var(--icon-blue);
            }
        }
    }

}

.dropdown-menu.show li:first-child {
    border-radius: 0.75rem 0.75rem 0 0;
}

.dropdown-menu.show li:last-child {
    border-radius: 0 0 .75rem .75rem;
}

.peer-indent {
    padding-left: 1.5rem !important;
}

.myrow {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}

.column {
    display: flex;
    flex-direction: column;
}

.prompt-group-name {
    font-weight: bold;
}
</style>