<template>
  <span class="hashtag-container">
    <span
      v-for="tag in response?.tags"
      :key="tag.tag"
      class="hashtag-text"
      :class="{ 'highlight-tag': shouldHighlightOnHover && tag === hoveredTag }"
      @click="handleTagButtonClick(tag)"
      @mouseover="handleTagHover(tag)"
      @mouseleave="handleTagLeave()"
    >
      #{{ tag.tag }}
    </span>
  </span>
</template> 

<script>
import { mapActions } from "vuex";
import { ROUTE } from "@/constants";

export default {
  props: ["response", "routeback", "shouldHighlightOnHover"],
  data() {
    return {
      route: ROUTE,
      hoveredTag: null,
    };
  },
  methods: {
    ...mapActions("ai", ["setAiTag"]),
    async handleTagButtonClick(tag) {
      await this.setAiTag(tag);
      const rawPath = this.route.tagDrilldown + "/" + this.routeback.replace(/\//g, '');
      this.$router.push({ path: rawPath });
    },
    handleTagHover(tag) {
      this.hoveredTag = tag;
      this.$emit("tagHover", tag);
    },
    handleTagLeave() {
      this.hoveredTag = null;
      this.$emit("tagHover", null);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_icons.scss";
@import "@/scss/_mixins.scss";

.hashtag-container {
  .hashtag-text {
    color: var(--primary-blue5);
    text-overflow: ellipsis;
    @include body3;
    cursor: pointer;
    text-transform: lowercase;
    &.highlight-tag {
      font-weight: 600;
    }
  }
}
</style>
