<template>
  <div class="top-sources-section">
    <table class="mt-1">
      <tr v-for="(source, index) in topSources" :key="source.domain" class="source-item">
        <td class="source-number">{{ index + 1 }}</td>
        <td class="source-name" @click="handleSourceButtonClick(source)">{{ source.domain }}</td>
        <td class="source-amount">{{ source.count }}</td>
        <!-- <span class="source-bar" :style="{ width: sourceWidth(source) + 'px' }" /> -->
        <td class="source-bar-container">
          <div class="source-bar" :style="{ width: sourceWidth(source) + 'px' }">
            <span class="percentage-text">{{ calculatePercentage(source.count, total) }}%</span>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { ROUTE } from "@/constants";
export default {
  props: {
    topSources: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      route: ROUTE,
    }
  },
  methods: {
    ...mapActions("ai", ["setAiTag"]),
    async handleSourceButtonClick(source) {
      console.log('not implemented', source);
    },
    calculatePercentage(count, total) {
      return Math.round(((count / total) * 100));
    } 
  },
  computed: {
    total() {
      // Calculate the total count of all sources
      return this.topSources.reduce((acc, source) => acc + source.count, 0);
    },    
    sourceWidth() {
      // Find the maximum frequency among tags
      const maxFrequency = Math.max(...this.topSources.map(source => source.count));

      // Set a maximum width for the tag bar
      const maxWidth = 110;

      // Calculate the width for a specific tag based on its frequency and the maximum width
      return source => Math.round((source.count / maxFrequency) * maxWidth);
    },

  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";

.top-sources-section {

  .source-number {
    margin-right: 8px;
    min-width: 20px;
    color: var(--grey6);
    @include body3;
  }

  .source-name {
    padding-right: .5rem;
    color: var(--primary-blue7);
    @include tag2-style;
    text-decoration-line: underline;
    cursor: pointer;
  }

  .source-amount {
    padding-right: .5rem;
    width: calc((min(100vw, 1400px)) / 18); 
    color: var(--grey9);
    @include body3;
  }

  .source-bar-container {
    position: relative;

    .source-bar {
      display: flex;
      width: 127px; 
      height: 22px; 
      padding-left: 12px;
      align-items: center;
      gap: 10px;
      border-radius: 0px 12px 12px 0px;
      background: var(--chart-blue6);
      position: relative;
      margin: .1rem 0;
    }

    .percentage-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white; 
      font-size: 10px; 
      font-weight: bold; 
    }
  }

}
</style>
