import { useRouter, useRoute } from 'vue-router';
import appConfig from '@/../config/config.json';

function pathOf(route) {
  return appConfig.routeBasePath + route;
}

function useRouteHelper() {

  const _route = useRoute();
  const _router = useRouter();

  function routeTo(route) {

    _router.replace({
      path: pathOf(route),
      query: { ..._route.query },
    });
  }

  function isRoute(route) {
    return pathOf(route) === _route.path;
  }

  return {
    routeTo,
    isRoute,
  };
}

export { useRouteHelper as default, pathOf };