<template>
  <div class="ai-engine form-field">

    <label v-if="!editMode">Select AI Engines</label>

    <div class="engines">
      <div v-for="aiModel in aiEngineModels" :key="aiModel">
        <input :id="aiModel.model_type + 'checkbox'" type="checkbox" class="btn-check"
               v-model="selectedAiEngineModels" :value="aiModel" />
        <label class="btn btn-outline-primary"
               :class="{ 'btn-selected': isAiEngineModelSelected(aiModel) }"
               :for="aiModel.model_type + 'checkbox'">
          <span class="icons" :class="aiModel.model_type"></span>
          <span class="label-text">{{ getModelName(aiModel.model_type) }}</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { AiModelDisplayNames } from "@/constants";
import { mapGetters } from "vuex";

export default {
  props: [
    "selectedAiEngineModelsProp",
    "editMode",
  ],

  data() {
    return {
    };
  },
  components: {},
  methods: {
    getModelName(modelType) {
      return AiModelDisplayNames[modelType];
    },
    normalizeInput(input) {
      return input.trim();
    },
    isAiEngineModelSelected(aiModel) {
      return this.selectedAiEngineModels.includes(aiModel);
    },
  },
  computed: {
    ...mapGetters("ai", {
      aiEngineModels: "aiEngineModels",
    }),

    selectedAiEngineModels: {
      get() {
        return this.selectedAiEngineModelsProp;
      },
      set(value) {
        this.$emit("setSelectedAiEngineModels", value);
      },
    },
  },
  watch: {},
  mounted() { },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";
@import "@/scss/icons.scss";
@import "@/scss/dialog-prompt-group-model.scss";

.engines {
  display: flex;
  flex-wrap: wrap;
  max-width: 20rem;
  gap: .5rem;

  label {
    display: flex;
    align-items: center;
    gap: .5rem;
    margin-bottom: 0;
  }

  .btn-check {
    &:checked+.btn {
      border-color: white;
      box-shadow: 1px 1px 3px var(--grey9);
    }

    &+.btn:hover {
      color: white;
      background-color: var(--primary-blue5);
    }
  }

  .btn {
    color: #000;
    width: 8rem;
    padding-left: .5rem;
    padding-right: .5rem;
    height: 36px;
    border-radius: 40px;

    .icons {
      background-color: white;
      border-radius: 40px;
      width: 20px;
      height: 20px;
      background-position: 2px 2px;
    }

    .label-text {
      @include body3;
      white-space: nowrap;
    }
  }
}
</style>
